import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setViewBulletinUploadForm, choosePDF, uploadBulletin, bulletinResetCreated } from 'features/bulletin/bulletinSlice'
import styled from 'styled-components' 
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { toast } from 'react-toastify';
import ModalCloseButton from 'components/buttons/ModalCloseButton';
import SpinnerSmall from 'components/SpinnerSmall';
import { FIH, FileUploadInput, FileInputWrap, ButttonWrap, FileUploadButton } from 'components/files/files.styled';
import { BiRename } from "react-icons/bi";
import { bulletinLanugage } from './bulletinLanguage';

export default function UploadBulletinModal() {
    const dispatch = useDispatch()
    const { 
        selectedPDF,
        createBulletinStatus,
        } = useSelector((state) => state.bulletin)
    const [bulletinName, setBulletinName] = useState('')
    const [pdfSelected, setPdfSelected] = useState(null)

    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { user } = useSelector((state) => state.auth)
    const handleBulletinName = (e) => {
        setBulletinName(e.target.value)
    }

    const handlePdfChange = (e) => {
        setPdfSelected(e.target.files[0].name)

        let fileName = e.target.files[0].name
        let fileSize = Math.round((e.target.files[0].size / 1024))
        let fileType = fileName.slice(fileName.lastIndexOf('.')+1)
        // let url = URL.createObjectURL(e.target.files[0])
        // dispatch(setPDF({ url: url }))
        
        if(fileType !== 'pdf'){
            setPdfSelected(null)
            dispatch(setViewBulletinUploadForm())
            return toast.error('Select a PDF file only')
        }
        if(fileSize >= 12000){
            setPdfSelected(null)
            dispatch(setViewBulletinUploadForm())
            return toast.error('Max file size is 12Mb')
        }
        dispatch(choosePDF(e.target.files[0]))
    }

    const handleUpload = () => {
        if(bulletinName.length === 0){
            return toast.error('Enter a name for your bulletin')
        }
        if(!selectedPDF){
            return toast.error('Select a PDF file')
        }
        const formData = new FormData();
        formData.append("name", bulletinName)      
        formData.append("file", selectedPDF)
        formData.append("churchId", profileChurch._id)
        dispatch(uploadBulletin(formData))

    }

    useEffect(() => {
        if(createBulletinStatus === 'SUCCESS'){
            setBulletinName('')
            // dispatch(setViewBulletinUploadForm())
            dispatch(bulletinResetCreated())
            toast.success('Bulletin Uploaded') 
            dispatch(setViewBulletinUploadForm(false))

        }
    }, [createBulletinStatus, dispatch])

    const handleCloseUploadForm = () => {
        dispatch(setViewBulletinUploadForm(false))
    }

  return ( 
        <BulletinFormWrap>
            <BulletinFormTop>
                <UploadFileIcon style={{ marginRight: '10px'}} />
                <FIHB >
                    {bulletinLanugage.uploadBulletin[user.preferredLanguage]}
                </FIHB> 
            </BulletinFormTop>                
            
            <ModalCloseButton
                    handler={handleCloseUploadForm}
                    size='30px'
                    top='10px'
                    right='10px'
                    /> 

            <FormWrap>
                    

                <FileInputWrap>
                    
                    <BiRename  style={{ color: 'var(--lightThemeFontColor)'}}/>
                    <FileUploadInput 
                        placeholder={bulletinLanugage.bulletinTitle[user.preferredLanguage]}
                        onChange={handleBulletinName} 
                        value={bulletinName}
                        />
                </FileInputWrap>
                
                
                
                <BulletinFile>
                    <BulletinFileChild1 > 
                        <BulletinFileName>
                            { pdfSelected ? (pdfSelected) :  (bulletinLanugage.noFileSelected[user.preferredLanguage]) }
                        </BulletinFileName>
                        <BulletinNameWrapInput  
                            hidden id="bulletinUpload"  
                            type="file"  accept=".pdf"  name="file" placeholder={'Ehl'}
                            onChange={handlePdfChange}
                            />                     
                    </BulletinFileChild1>
                    <ChooseFileBtn htmlFor="bulletinUpload"> 
                        
                        <span>
                            {bulletinLanugage.selectPdfFile[user.preferredLanguage]}
                        </span>
                    </ChooseFileBtn>
                </BulletinFile>


            </FormWrap>
            <ButttonWrap
                    style={{ marginTop: '35px', marginBottom: '20px'}}
                    >
                    <FileUploadButton
                        width={'250px'}
                        disabled={createBulletinStatus === 'LOADING'}
                        onClick={handleUpload}
                        >
                        
                        {
                            createBulletinStatus === "LOADING" ? 
                            (
                                <SpinnerSmall />
                            ) 
                            : 
                            (    
                                <span>
                                    {
                                        bulletinLanugage.uploadBulletin[user.preferredLanguage]
                                    }
                                </span>      
                            )
                        }
                        
                    </FileUploadButton>                    
                </ButttonWrap>
        </BulletinFormWrap> 
  )
}


const BulletinFormWrap = styled.div`
    z-index: 0;
    width: 550px;
    min-height: 350px;
    height: fit-content;
    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 1px;
    position: relative;
    animation: animate 0.3s;
    padding: 10px 20px;
    @media (max-width: 768px) {
        align-self: center;
        justify-self: center;
        width: 100%;
        height: fit-content
    }
`
const BulletinFormTop = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--lightThemeFontColor);
    justify-content: flex-start;   
    margin-bottom: 20px;
`
const FIHB = styled.h3`
    color: var(--lightThemeFontColor);
    font-size: 18px; 
    font-weight: 500;
    width: 100%;
    text-align: left;

`

const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
`

const BulletinNameWrap = styled.div`
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    input::placeholder{
        color: #3b3b3b;
    }
`
const BulletinNameWrapInput = styled.input`
    width: 100%;
    color: var(--lightThemeFontColor);
    padding: 15px;
    outline: none;
    border: none;
    border-radius: 5px;
    border: 1px solid var(--borderColor);
`

const BulletinFile = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
    border-radius: 5px;
    justify-content: space-between;
    /* border: 1px solid var(--borderColor); */
`

const BulletinFileChild1 = styled.div`
    display: flex;
    flex-direction: column;
    width: 65%;
    align-items: flex-start;
    
`

const BulletinFileName = styled.div`
    background-color: #dfdfdfd0;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    color: var(--lightThemeFontColor);
`

const ChooseFileBtn = styled.label` 
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    height: 40px;
    background-color: #0b550b;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.4s;
    font-size: small;
    justify-content: center;
    &:hover{
        background-color: green;
    }
`

