import React from 'react'
import { InputLabel, InputItemWrap, DHA, AuthButton, ForgotPasswordButton, FormContainer, LoginHeader, FormMain, LoginFooter, FormMainHeader, InputFieldItem } from 'pages/auth/auth.styled'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom' 
import { register, registerChurch } from '../../features/auth/authSlice'
import { verifyRegisterData, resetAuth, setForgotPasswordUserType, setViewForgotPassword,  setViewEmailConfirmModal, sendVerificationEmailCode } from '../../features/auth/authSlice'  
import ReCAPTCHA from 'react-google-recaptcha'
import {useState, useEffect, useRef} from 'react' 
import SelectUserType from './SelectUserType'
import { toast } from 'react-toastify'

// import select from MUI
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import SpinnerSmall from 'components/SpinnerSmall'

const countries = [
    { name: 'Select', code: '' },
    { name: 'Canada', code: 'CA' },
    { name: 'Mexico', code: 'MX' },
    { name: 'United States', code: 'US' },
]

export default function RegisterForm() {

    const dispatch = useDispatch()
    const recaptcha = useRef();
    const email = useRef();
    const [errorField, setErrorField] = useState({
        fullName: false,
        email: false,
        userType: false,
        username: false,
        password: false,
        password2: false
    })
    const fullName = useRef();
    const username = useRef();
    const country = useRef();
    const [countryValue, setCountryValue] = useState(countries[0].name)
    const password2 = useRef();
    const password = useRef();
    const navigate = useNavigate() 
    const { 
        isEmailCodeVerificationSuccess, userType, isRegisterLoading,
        codeVerified, isRegisterSuccess, isRegisterError, registerErrorMessage, verifyRegisterDataLoading,
        verifyRegisterDataSuccess, verifyRegisterDataError, verifyRegisterDataMessage
    } = useSelector( (state) => state.auth )

    const handleRegister = () => { 
 
        if(!recaptcha.current.getValue() ){
            toast.error('Please complete the captcha')
            return
        }

        let emailValue = email.current.value
        let emailType = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if( userType === 'none' ) {
            setErrorField({ ...errorField, userType: true })
            toast.error('Please select account type')
            return
        }

        if( userType === 'church' && fullName.current.value === '' ) {
            setErrorField({ ...errorField, fullName: true })
            toast.error('Church name is required')
            return
        }

        if( emailValue === '' ) {
            setErrorField({ ...errorField, email: true })
            toast.error('Email is required')
            return
        }

        if( emailType.test(emailValue) === false ) {
            setErrorField({ ...errorField, email: true })
            toast.error('Email is invalid')
            return
        }

        if( userType === 'church' && username.current.value === '' ) {
            setErrorField({ ...errorField, username: true })
            toast.error('Username is required')
            return
        }

        if( password.current.value === '' ) {
            setErrorField({ ...errorField, password: true })
            toast.error('Password is required')
            return
        }

        if( password2.current.value === '' ) {
            setErrorField({ ...errorField, password2: true })
            toast.error('Password is required')
            return
        }

        if( password.current.value !== password2.current.value ) {
            setErrorField({ 
                ...errorField,
                password: true,
                password2: true 
             })
            toast.error('Passwords must match')
            return
        } 

        dispatch(verifyRegisterData({
            email: email.current.value,
            userType: userType,
            username: userType === 'church' ? username.current.value : ''
        })) 

    }

    const sendEmailCode = () => {

        dispatch(setViewEmailConfirmModal({
            email: email.current.value,
        }))
  
        dispatch(sendVerificationEmailCode({
            fullName: fullName.current.value,
            email: email.current.value,
        })) 
    }

    const submitRegister = () => {
        if( userType === 'church') {
            dispatch(registerChurch({
                fullName: fullName.current.value,
                email: email.current.value,
                username: username.current.value,
                country: 'null',
                password: password.current.value,
                captchaValue: recaptcha.current.getValue()
            }))
        }else{
            dispatch(register({ 
                fullName: fullName.current.value,
                email:  email.current.value,
                password: password.current.value,
                captchaValue: recaptcha.current.getValue()
            }))
        }
    }

    const handleForgotPassword = () => {
        dispatch(setForgotPasswordUserType(userType))
        dispatch(setViewForgotPassword(true))
    }

    useEffect(() => { 
        if(isEmailCodeVerificationSuccess && codeVerified){
          submitRegister()
        }
    }, [isEmailCodeVerificationSuccess, codeVerified])

    useEffect(() => {
        if( verifyRegisterDataError ) {
            toast.error( verifyRegisterDataMessage )
        } else if( verifyRegisterDataSuccess ) {
            sendEmailCode()
        }
    }, [verifyRegisterDataError, verifyRegisterDataSuccess, verifyRegisterDataMessage])



    useEffect(() => {
        if (isRegisterError) { 
            
            toast.error( registerErrorMessage )

        } else if (isRegisterSuccess) { 
          
            navigate('/login')
            toast.success('Account Created, Login to Continue!')
            dispatch(resetAuth())
        } 
    }, [isRegisterSuccess, isRegisterError, registerErrorMessage, navigate, dispatch])

    const handleNavigate = () => {
        navigate('/login')
    }

    return (
        <FormContainer
            formType={'register'}
            >
            <LoginHeader>
                <img
                    style={{ width: "100px", height: "40px" }} 
                    src={require("../../prayselyLogo.png")} alt="logo" />
            </LoginHeader>
            <FormMain
                formType={'register'}
                >  
                <FormMainHeader>
                    <h1>Create an account!</h1>
                    <p>Choose account type </p>
                </FormMainHeader>

                <SelectUserType />

                {
                    userType !== 'none' && (

                        <>
 
                            <InputItemWrap>
                                <InputLabel>
                                    Name
                                </InputLabel>
                                <InputFieldItem
                                    isError={errorField.fullName}
                                    >
                                    <input
                                        ref={fullName} 
                                        type="text" 
                                        placeholder={ userType === 'church' ? ('Enter Your Church Name') : ('Enter Your Name') }
                                        autoComplete='off'
                                        /> 
                                </InputFieldItem>
                            </InputItemWrap> 
                            <InputItemWrap>
                                <InputLabel>
                                    Email
                                </InputLabel>
                                <InputFieldItem
                                    isError={errorField.email}
                                    >
                                    <input
                                        ref={email} 
                                        type="email" 
                                        placeholder="Email" 
                                        autoComplete='off'
                                        /> 
                                </InputFieldItem>
                            </InputItemWrap>

                            {
                                userType === 'church' && (
                                    <>
                                        <InputItemWrap>
                                            <InputLabel>
                                                User name
                                            </InputLabel>
                                            <InputFieldItem
                                                isError={errorField.username}
                                                >
                                                <input
                                                    ref={username} 
                                                    type="text" 
                                                    placeholder="Add a uniquely identifying username"
                                                    autoComplete='off' 
                                                    /> 
                                            </InputFieldItem>
                                        </InputItemWrap>  
                                        {/* <InputItemWrap>
                                            <InputLabel> Country </InputLabel>
                                            <FormControl fullWidth>
                                                <Select 
                                                    id='demo-simple-select'
                                                    style={{ border: 'none', outline: 'none'  }} 
                                                    inputProps={{ 'aria-label': 'Without label' }} 
                                                    sx={{ 
                                                        width: '100%',  
                                                        border: 'none', 
                                                        outline: 'none',
                                                        '& legend': { display: 'none' }, '& fieldset': { top: 0 }
                                                    }}
                                                    displayEmpty 
                                                    value={countryValue}
                                                    renderValue={(selected) => {
                                                        return selected
                                                    }}
                                                    label="Country"
                                                    ref={country}
                                                > 
                                                    {
                                                        countries.map((country, index) => {
                                                            return (
                                                                <MenuItem 
                                                                    key={index} 
                                                                    value={country.name}
                                                                    onClick={() => {
                                                                        setCountryValue(country.name)
                                                                    }}
                                                                    >
                                                                    {country.name}
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </InputItemWrap> */}
                                    </>                       
                                )
                            } 
                            <InputItemWrap>
                                <InputLabel>
                                    Password
                                </InputLabel>
                                <InputFieldItem
                                    isError={errorField.password || (errorField.password && errorField.password2) }
                                    >
                                    <input
                                        ref={password} 
                                        type="password" 
                                        placeholder="Password" 
                                        autoComplete='off'
                                        /> 
                                </InputFieldItem>
                            </InputItemWrap>

                            <InputItemWrap>
                                <InputLabel>
                                    Confirm Password
                                </InputLabel>
                                <InputFieldItem
                                    isError={errorField.password2 || (errorField.password && errorField.password2) }
                                    >
                                    <input
                                        ref={password2} 
                                        type="password" 
                                        placeholder="Password" 
                                        autoComplete='off'
                                        /> 
                                </InputFieldItem>
                            </InputItemWrap>

                            <ReCAPTCHA 
                                ref={recaptcha}
                                sitekey={"6LfmM8QqAAAAANqh0Ia0RyQIGjJ3hHDK5EB-f6Xr"}
                                />


                            <AuthButton onClick={handleRegister} >
                                {
                                    isRegisterLoading ? (
                                        <SpinnerSmall />
                                    ) : (
                                        <>
                                         Register as <span style={{textTransform: 'capitalize'}}>{userType }</span>
                                        </>
                                    )
                                }
                                   
                            </AuthButton>
                            
                            <DHA>
                                <span>Already have an account? </span>                               
                                <p onClick={handleNavigate} >
                                    Login
                                </p>
                            </DHA>
                        </>
                    )
                }
 

            </FormMain>
            <LoginFooter>
                <span> &copy; 2025 Praysely, Inc </span>
            </LoginFooter>
        </FormContainer>
    )
}
