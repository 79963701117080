import GlobalBtnWrapper from 'components/buttons/GlobalBtnWrapper'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { btnHeight, btnWidth,  } from 'app/globalSettings'
import { setViewSidebar } from 'features/sidebar/sidebarSlice'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { language } from 'app/language'

export default function PrayerWallButton() {

    const dispatch = useDispatch()
    const { viewSidebar } = useSelector((state) => state.sidebar)
    const { user } = useSelector((state) => state.auth)

    const handleViewPrayerWall = () => {

        viewSidebar === "prayerWall" ?
            dispatch(setViewSidebar("none"))
            :
            dispatch(setViewSidebar("prayerWall"))

    }

    return (
        <GlobalBtnWrapper 
            height={btnHeight}
            width={btnWidth}
            boxShadow={true}
            theme={'light'}
            >
            <PrayerWallButtonContainer
                onClick={handleViewPrayerWall}
                >
                    { viewSidebar === "prayerWall" ? 
                        (
                            <VisibilityOffIcon style={{marginRight: '5px', fontSize: '16px'}}/>
                        )
                        :
                        (
                            <FormatListBulletedIcon style={{marginRight: '5px', fontSize: '16px'}}/>
                        )
                    } 
                    <span>
                        {viewSidebar === "prayerWall" ? 
                            (
                                language.hidePrayerWall[user.preferredLanguage]
                            )
                            :
                            (
                                language.viewPrayerWall[user.preferredLanguage]
                            )
                        }
                    </span>
            </PrayerWallButtonContainer>
        </GlobalBtnWrapper>
    )
}

const PrayerWallButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; 
    width: fit-content; 
    background-color: white;
    color: var(--lightThemeFontColor);
    border-radius: 10px;
    padding: 0 10px;
    font-size: 12px; 
    cursor: pointer;
    transition: all 0.2s ease-in-out; 
    @media (max-width: 768px) {
        display: none;
    }
`