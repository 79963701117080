import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { addNewMessageNotification, createMessage } from '../../features/messenger/messengerSlice'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import SpinnerSmall from 'components/SpinnerSmall'
import { VscSend } from "react-icons/vsc";
import { RiImageAddFill } from "react-icons/ri";
import { Tooltip } from '@mui/material'
import { MdClose } from "react-icons/md";

function NewMessageForm( { id } ) {
    const dispatch = useDispatch()
    const [messageText, setMessageText] = useState('')
    const [image, setImage] = useState(null)
    const [imagePreview, setImagePreview] = useState(null)

    const handelRemoveImage = () => {
        setImage(null)
        setImagePreview(null)
    }
    
    // get params
    const { mm } = useParams()
    const [isAllowed , setIsAllowed] = useState(false)
    const { 
        isCreateError, 
        isCreateSuccess, 
        isCreateLoading, 
        createMessageError, 
        lstMsgUsrId,
        lstMsgUsrTyp,
        hiddenUsers
    } = useSelector(state => state.messages)

    const handleChange = (e) => {
        if(messageText.length >= 3 ){setIsAllowed(true)}else{setIsAllowed(false)}
        setMessageText(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault() 
        if(messageText.length < 3 && !image){
            toast.error('Message must contain something...') 
        }else{

            let formData = new FormData()

            if(image){
                formData.append('file', image)
            }

            formData.append('text', messageText)
            formData.append('isMember2member', mm ? true : '')
            formData.append('conversationId', id)

            dispatch(createMessage( formData ))
        }
        setMessageText('')
        setImage(null)
        setImagePreview(null)
    }

    const handleAddImage = () => { 
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = 'image/*'
        input.click()
        input.onchange = (e) => {
            const file = e.target.files[0]
            if(file && file.type.substr(0, 5) === 'image'){
                setImage(file)
                const reader = new FileReader()
                reader.onload = () => {
                    setImagePreview(reader.result)
                }
                reader.readAsDataURL(file)
            }else{
                toast.error('Invalid File Type. Please select an image file')
            }
        } 
    }

    // doing this in the backend
    
    // useEffect(() => {
    //     if(isCreateSuccess && lstMsgUsrId !== null){
    //         if(!hiddenUsers.includes(lstMsgUsrId)){
    //             dispatch(addNewMessageNotification({
    //                 otherUserType: lstMsgUsrTyp,
    //                 otherUserId: lstMsgUsrId,
    //                 convId: id,
    //             }))
    //         }
    //     }
    // }, [isCreateSuccess, lstMsgUsrId, lstMsgUsrTyp, hiddenUsers, id, dispatch])

    return (
        <MsgFormContainer>

            {
                imagePreview && 
                (
                    <ImagePreviewMsg>
                        <Tooltip title='Remove Image' placement='top'>
                            <RemoveImgBtn>
                                <MdClose 
                                    size={24} 
                                    color='white'
                                    style={{cursor: 'pointer'}} 
                                    onClick={handelRemoveImage} 
                                    />
                            </RemoveImgBtn>
                        </Tooltip>
                        <img 
                            src={imagePreview} 
                            alt='preview' 
                             />                        
                    </ImagePreviewMsg>

                )
            }

            <MsgForm onSubmit={handleSubmit}>

                <Tooltip title='Add Image' placement='top'>
                    <AddItemsBtn
                        onClick={handleAddImage}
                        type='button' 
                        >
                        <RiImageAddFill size={24} />
                    </AddItemsBtn>
                </Tooltip>

                <MsgInput 
                    value={messageText} 
                    onChange={handleChange} 
                    placeholder='Enter New Message...' 
                    style={{resize: 'none'}}
                    />

                <SendMsgBtn 
                    type='submit' 
                    disabled={isCreateLoading || !isAllowed}
                    >
                    {
                        isCreateLoading ? 
                        (
                            <SpinnerSmall />
                        )
                        :
                        (
                            <VscSend 
                                size={24}
                                />
                        )
                    } 
                </SendMsgBtn>
            </MsgForm>
        </MsgFormContainer>
    )
}

export default NewMessageForm

const RemoveImgBtn = styled.div`
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #4a4a4a70;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
`

const ImagePreviewMsg = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    width: fit-content  ;
    align-self: center;
    justify-self: center;
    & img {
        width: 100%;
        height: 100px;
        border-radius: 5px;
        object-fit: cover;
        box-shadow: 0 0 10px #707070  ;
    }
`

const AddItemsBtn = styled.button`
    width: 50px;
    height: 50px;
    background-color: #999;; 
    border: none;
    border-radius: 50px;
    color: white;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px white  ;
    transition: all 0.3s ease;

    @media (max-width: 768px) {
        
        width: 45px;
        height: 45px;
        
    }

    &:hover{
        background-color: #606060;
    }

`

const MsgFormContainer = styled.div`
    width: 100%;
    background-color: rgb(222, 221, 221);
    padding: 15px;
    

    @media (max-width: 768px) {
        padding: 15px;
    }
 

`

const MsgForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    height: fit-content;
`

const MsgInput = styled.textarea`  
    width: 400px;
    padding: 10px;
    height: 70px;
    outline: none;
    border-radius: 5px; 
    box-shadow: 2px 2px 2px rgb(201, 201, 201);
    margin-right: 5px;
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    border: 3px solid transparent;
    transition: all 0.3s ease;

    @media (max-width: 768px) {
        width: 340px;
    }
    &::placeholder{
        color: #9d9d9d;
        font-size: 15px;
        font-family: 'Roboto', sans-serif;
    }

    &:focus{
        border: 3px solid #ccc;
        box-shadow: 0 0 0px #ccc;
    }

`

const SendMsgBtn = styled.button`
    width: 50px; 
    background-color: #063c75;
    border: none;
    border-radius: 50%;
    height: 50px;
    color: white;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    @media (max-width: 768px) {
        
        width: 45px;
        height: 45px;
        
    }
`
 