import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { getPrayerWallItems, setViewSidebar } from 'features/sidebar/sidebarSlice'
import dayjs from 'dayjs'

export default function EventsFeed() {

    const { eventItem, getEventItemsStatus } = useSelector((state) => state.sidebar)

    return (
        <SidebarItemContainer>

            {
                eventItem !== null && (
                    <SBW>
                        <h2>{eventItem.name}</h2> 
                        <p>
                            {'Starts: '}
                            {dayjs(eventItem.start).format('ddd MMM DD')}
                            {' @ '}
                            {dayjs(eventItem.start).format(' h:mma')}
                        </p>
                        <p>
                            
                            {'Ends: '}
                            {dayjs(eventItem.end).format('ddd MMM DD')}
                            {' @ '}
                            {dayjs(eventItem.end).format(' h:mma')}
                        </p>
                    </SBW>
                    
                )
            }

            
        </SidebarItemContainer>
    )
}

const SBW = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;

`

const SidebarItemContainer = styled.div` 
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    h2 {
        font-size: 1.2rem;
        font-weight: 600;
        color: var(--lightThemeFontColor);
        border-bottom: 1px solid var(--lightThemeFontColor);
        padding-bottom: 5px;
        margin-bottom: 5px;    
    }
    p{
        font-size: 14px;
        font-weight: 400;
        color: var(--lightThemeFontColor);
        margin-top: 5px;
    }

`