import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import emailService from './emailService'
import { STATUS } from 'features/enums'

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

const initialState = {
  emailList: [], 
  sendEmailInviteStatus: STATUS.NOT_STARTED,  
} 

 
  export const sendGivingTransactionEmail = createAsyncThunk(
    'email/sendGivingTransactionEmail',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await emailService.sendGivingTransactionEmail(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    } 
  )

  export const sendEmailInvite = createAsyncThunk(
    'email/sendEmailInvite',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await emailService.sendEmailInvite(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    } 
  ) 

  export  const emailSlice = createSlice({
    name: 'email',
    initialState,
    reducers: {
        emailReset: (state) => initialState, 
        resetAddEmailState: (state) => {
            state.emailList = []
            state.sendEmailInviteStatus = STATUS.NOT_STARTED
        }
    },
    extraReducers: (builder) => {
      builder
        .addCase(sendEmailInvite.pending, (state) => {
          state.sendEmailInviteStatus = STATUS.LOADING
        })
        .addCase(sendEmailInvite.fulfilled, (state) => {
          state.sendEmailInviteStatus = STATUS.SUCCESS
        })
        .addCase(sendEmailInvite.rejected, (state, action) => {
          state.sendEmailInviteStatus = STATUS.ERROR
          state.sendEmailInviteErrorMessage = action.payload
        })
      },
})

export const { 
    emailReset,
    resetAddEmailState
} = emailSlice.actions
export default emailSlice.reducer

