import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import eventService from './eventService'
import { STATUS } from 'features/enums'

const errorMessage = (error) => {
    return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
  }

const initialState = {

  eventsList: [],
  calendarItems: [],
  pastEvents: [],
  upcomingEvents: [],
  singlePageEventData: null, 
  createCalendarItemStatus: STATUS.NOT_STARTED,
  myEvent: null,
  isEventError: false,
  isEventSuccess: false,
  isEventLoading: false,
  isEventCreatedSuccess: false,
  isEventUpdatedSuccess: false,
  isEventUpdatedLoading: false,
  isEventCreatedLoading: false,
  eventMessage: '',
  viewEventModal: false,
  viewEventModalEdit: false,
  eventEditData: {},
  eventEditIndex: null,
  eventImagePreview: {},
  selectedImage: {},
  isResponseLoading: false,
  isResponseSuccess: false,
  responseMessage: '',
  preselectedDate: null,

  eventItemPagination: {
    page: 1,
    pages: 0,
    totalEvents: 0,
    hasNextPage: false,
    hasPrevPage: false,
    nextPage: null,
    prevPage: null,
    filter: '',
    sort: '',
  },

  eventCommentsPagination: {
    page: 1,
    pages: 0,
    totalComments: 0,
    hasNextPage: false,
    hasPrevPage: false,
    nextPage: null,
    prevPage: null,
  },

  isResponseError: false,
  isEventSaved: false,
  isEventDeleted: false,

  isSingleEventLoading: false,
  isSingleEventSuccess: false,
  isSingleEventError: false,

  addEventDiscussionCommentLoading: false,
  addEventDiscussionCommentSuccess: false,
  addEventDiscussionCommentError: false,

  addEventDiscussionReplyLoading: false,
  addEventDiscussionReplySuccess: false,

  eventDiscussionComments: [],

  isEventDiscussionCommentsLoading: false,
  isEventDiscussionCommentsSuccess: false,
  isEventDiscussionCommentsError: false,

  latestReply: {
    commentId: null,
    reply: null,
  },
 
  commentIdsOfRepliesLoaded: [],
  commentRepliesLoading: false,
  commentIdReplies: null,
  commentRepliesSuccess: false,
  commentIndexLoading: {
    commentId: null,
    index: null,
    loading: false,
    status: null,
  },

  isSavedContentLoading: false,
  isSavedContentSuccess: false,
  isSavedContentError: false,
  
  eventsSaved: [],
  eventsInterestedIn: [],
  eventsAttending: [],
  eventFilter: '',

  viewRsvpModal: false,
  viewCalendarItemInfoModal: false,
  viewAddCalendarItemModal: false,
  rsvpData: null,

}


export const getEvents = createAsyncThunk(
    'events/getEvents',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await eventService.getEvents(data, token)
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const createEvent = createAsyncThunk(
    'events/create',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await eventService.createEvent(data, token)
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const createCalendarItem = createAsyncThunk(
    'events/createCalendarItem',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await eventService.createCalendarItem(data, token)
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )


  export const updateEvent = createAsyncThunk(
    'events/update',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await eventService.updateEvent(data, token)
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )


  export const sendResponse = createAsyncThunk(
    'events/sendResponse',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await eventService.sendResponse(data, token)
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )
    

  export const saveEvent = createAsyncThunk(
    'events/save',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await eventService.saveEvent(data, token)
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )


  export const deleteEvent = createAsyncThunk(
    'events/delete',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await eventService.deleteEvent(data, token)
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  
  export const getSavedEvents = createAsyncThunk(
    'events/getSavedEvents',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await eventService.getSavedEvents(data, token)
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const getEventDiscussionComments = createAsyncThunk(
    'events/getEventDiscussionComments',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await eventService.getEventDiscussionComments(data, token)
      } catch (error) {
        const message = errorMessage(error) 
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const deleteEventDiscussionComment = createAsyncThunk(
    'events/deleteEventDiscussionComment',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await eventService.deleteEventDiscussionComment(data, token)
      } catch (error) {
        const message = errorMessage(error) 
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const getSingleEventData = createAsyncThunk(
    'events/getSingleEventData',
    async (data, thunkAPI) => {
      try {
        // const token = thunkAPI.getState().auth.user.token
        return await eventService.getSingleEventData(data)
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )
  export const getSingleEventDataLoggedIn = createAsyncThunk(
    'events/getSingleEventDataLoggedIn',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await eventService.getSingleEventDataLoggedIn(data, token)
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const addEventDiscussionComment = createAsyncThunk(
    'events/addEventDiscussionComment',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await eventService.addEventDiscussionComment(data, token)
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const addEventDiscussionReply = createAsyncThunk(
    'events/addEventDiscussionReply',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await eventService.addEventDiscussionReply(data, token)
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const getEventDiscussionCommentReplies = createAsyncThunk(
    'events/getEventDiscussionCommentReplies',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await eventService.getEventDiscussionCommentReplies(data, token)
      } catch (error) {
        const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )


  export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        resetEvent: (state) => initialState,
        setViewEventModal: (state, action) => { 
          if (action.payload){
            if(action.payload.startDate){
              state.preselectedDate = action.payload.startDate
            }
          }else{
            state.preselectedDate = null 
          }
          state.viewEventModal = true
        },
        setViewEventModalEdit: (state, action) => {

          state.eventEditData = action.payload.data
          state.preselectedDate = new Date (action.payload.data.startDate)
          state.eventEditIndex = action.payload.index

          state.viewEventModalEdit = true

        },
        hideEventModal: (state, action) => {
          state.viewEventModal = false
          state.viewEventModalEdit = false
          state.eventEditData = {}
          state.eventEditIndex = null
          state.eventImagePreview = {}
        },
        setEventImagePreview: (state, action) => {state.eventImagePreview = action.payload},
        chooseEventImage: (state, action) => {state.selectedImage = action.payload},
        removeEventImage: (state) => {state.eventImagePreview = initialState.eventImagePreview},
        setEventFilter: (state, action) => {state.eventFilter = action.payload},
        setSinglePageEventData: (state, action) => {

          // state.singlePageEventData = action.payload.eventData

          state.singlePageEventData = {
            ...action.payload.eventData,
            churchId: action.payload.churchInfo
          }

        },
        setLoadingCommentIdReplies: (state, action) => {

          let newObj = {
            commentId: action.payload.commentId,
            index: action.payload.index,
            loading: action.payload.loading,
            status: action.payload.status,
          }

          state.commentIndexLoading = newObj

        },
        resetLatestReply: (state, action) => {
          state.latestReply = null
        },
        setDateSelectedFromCalendar: (state, action) => {
          state.dateSelectedFromCalendar = action.payload
        },
        setViewRsvpModal: (state, action) => {  
          state.viewRsvpModal = action.payload
        },
        setRsvpData: (state, action) => {  
          state.rsvpData = action.payload
        },
        setViewCalendarItemInfoModal : (state, action) => {
          state.viewCalendarItemInfoModal = action.payload
        },
        setViewAddCalendarItemModal: (state, action) => {
          state.viewAddCalendarItemModal = action.payload
        }
    },
    extraReducers: (builder) => {
      builder
        .addCase(getEvents.pending, (state) => {
          state.isEventLoading = true
        })
        .addCase(getEvents.fulfilled, (state, action) => {
          state.isEventLoading = false
          state.isEventSuccess = true
          state.eventsList = action.payload.upcomingEvents
          state.calendarItems = action.payload.calendarItems
        })
        .addCase(getEvents.rejected, (state, action) => {
          state.isEventLoading = false
          state.isEventSuccess = false
          state.eventMessage = action.payload
        })
        //------------------
        .addCase(createEvent.pending, (state) => {
          state.isEventCreatedLoading = true
        })
        .addCase(createEvent.fulfilled, (state, action) => {
          state.isEventCreatedLoading = false
          state.isEventCreatedSuccess = true
          state.eventsList.push(action.payload) // fix this
        })
        .addCase(createEvent.rejected, (state, action) => {
          state.isEventCreatedLoading = false
          state.isEventCreatedSuccess = false
          state.eventMessage = action.payload
        })
        //------------------

        .addCase(sendResponse.pending, (state) => {
          state.isResponseLoading = true
          })
        .addCase(sendResponse.fulfilled, (state, action) => {
          state.isResponseSuccess = true
          state.isResponseLoading = false
          

          state.singlePageEventData = {
            ...state.singlePageEventData,
            attending: action.payload.attending,
          }

          state.viewRsvpModal = false

          // commented out since we are updating the event only in the single page section, not in the list
          // state.eventsList[action.payload.index] = action.payload.updatedEvent
        })
        .addCase(sendResponse.rejected, (state, action) => {
          state.isResponseLoading = false
          state.isResponseSuccess = false
          state.isResponseError = true
          state.responseMessage = action.payload
        })
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(saveEvent.pending, (state) => {
          state.isEventSaved = false
        })
        .addCase(saveEvent.fulfilled, (state, action) => {
          state.isEventSaved = true
        })
        .addCase(saveEvent.rejected, (state, action) => {
          state.isEventSaved = false
        })
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(updateEvent.pending, (state) => {
          state.isEventUpdatedLoading = true
        })
        .addCase(updateEvent.fulfilled, (state, action) => {
          state.isEventUpdatedLoading = false
          state.isEventUpdatedSuccess = true
          state.eventsList[action.payload.index] = action.payload.updatedEvent
          state.viewEventModalEdit = false
        })
        .addCase(updateEvent.rejected, (state, action) => {
          state.isEventError = true
          state.eventMessage = action.payload.message
        })
           //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(deleteEvent.pending, (state) => {
          state.isEventDeleted = false
        })
        .addCase(deleteEvent.fulfilled, (state, action) => {
          state.isEventDeleted = true
          state.eventsList = state.eventsList.filter(
            (element) => element._id !== action.payload.deletedId
          )
        })
        .addCase(deleteEvent.rejected, (state, action) => {
          state.isEventDeleted = false
          // state.eventMessage = action.payload
        })
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%---------------------------

        .addCase(getSavedEvents.pending, (state) => {
          state.isSavedContentLoading = false
        })
        .addCase(getSavedEvents.fulfilled, (state, action) => {
          state.isSavedContentLoading = false
          state.isSavedContentSuccess = true
          state.eventsSaved = action.payload
        })
        .addCase(getSavedEvents.rejected, (state, action) => {
          state.isEventSaved = false
        })
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(getEventDiscussionComments.pending, (state) => {
          state.isEventDiscussionCommentsLoading = true
        })
        .addCase(getEventDiscussionComments.fulfilled, (state, action) => {
          state.isEventDiscussionCommentsLoading = false
          state.isEventDiscussionCommentsSuccess = true
          state.eventDiscussionComments = action.payload.eventDiscussionComments
        })
        .addCase(getEventDiscussionComments.rejected, (state, action) => {
          state.isEventDiscussionCommentsLoading = false
          state.isEventDiscussionCommentsError = true
        })
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(deleteEventDiscussionComment.pending, (state) => {
          state.isEventDiscussionCommentsLoading = true
        })
        .addCase(deleteEventDiscussionComment.fulfilled, (state, action) => {
          state.isEventDiscussionCommentsLoading = false
          state.isEventDiscussionCommentsSuccess = true
          state.eventDiscussionComments = state.eventDiscussionComments.filter(
            (element) => element._id !== action.payload.id
          )
        })
        .addCase(deleteEventDiscussionComment.rejected, (state, action) => {
          state.isEventDiscussionCommentsLoading = false
          state.isEventDiscussionCommentsError = true
        })
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(getSingleEventData.pending, (state) => {
          state.isSingleEventLoading = true
        })
        .addCase(getSingleEventData.fulfilled, (state, action) => {
          state.isSingleEventLoading = false
          state.isSingleEventSuccess = true
          state.singeEventPrivacy = action.payload.event.privacy
          state.singlePageEventData = action.payload.event
        })  
        .addCase(getSingleEventData.rejected, (state, action) => {
          state.isSingleEventLoading = false
          state.isSingleEventError = true
        })
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(getSingleEventDataLoggedIn.pending, (state) => {
          state.isSingleEventLoading = true
        })
        .addCase(getSingleEventDataLoggedIn.fulfilled, (state, action) => {
          state.isSingleEventLoading = false
          state.isSingleEventSuccess = true
          state.singeEventPrivacy = action.payload.event.privacy
          state.singlePageEventData = action.payload.event
        })  
        .addCase(getSingleEventDataLoggedIn.rejected, (state, action) => {
          state.isSingleEventLoading = false
          state.isSingleEventError = true
        })
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(addEventDiscussionComment.pending, (state) => {
          state.addEventDiscussionCommentLoading = true
        })
        .addCase(addEventDiscussionComment.fulfilled, (state, action) => {
          state.addEventDiscussionCommentLoading = false
          state.addEventDiscussionCommentSuccess = true

          // since this is a new comment, instead of populating with database we can just add users data
          // to the return object. This is done in the backend with the addition of the "myData" field
          // the reason why this is important is to make it faster to load the new comment and image.
          action.payload.newComment.authorId = action.payload.myData

          state.eventDiscussionComments.push(action.payload.newComment)
        })
        .addCase(addEventDiscussionComment.rejected, (state, action) => {
          state.addEventDiscussionCommentLoading = false
          state.addEventDiscussionCommentError = true
        })
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(addEventDiscussionReply.pending, (state) => {
          state.addEventDiscussionReplyLoading = true

        })
        .addCase(addEventDiscussionReply.fulfilled, (state, action) => {
          state.addEventDiscussionReplyLoading = false
          state.addEventDiscussionReplySuccess = true

          // since this is a new comment, instead of populating with database we can just add users data
          // to the return object. This is done in the backend with the addition of the "myData" field

          // ** a better implementation version of this ^^ is in singlePostCommentSlice

          let replyAuthor = action.payload.myData     
          action.payload.newReply.authorId = replyAuthor

          state.latestReply = {
            commentId: action.payload.updatedComment._id,
            reply: action.payload.newReply
          }

          state.eventDiscussionComments[action.payload.index].replies.unshift(action.payload.newReply)

          // state.eventDiscussionComments[action.payload.index] = action.payload.updatedComment
        })
        .addCase(addEventDiscussionReply.rejected, (state, action) => { 
          state.addEventDiscussionReplyLoading = false
          state.addEventDiscussionCommentError = true
        })
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(getEventDiscussionCommentReplies.pending, (state) => {
          state.commentRepliesLoading = true
        })
        .addCase(getEventDiscussionCommentReplies.fulfilled, (state, action) => {


          /// add a field to denote that these comments are available
          // we can access this by referencing the array index of the comment
          state.eventDiscussionComments[action.payload.index].repliesLoaded = action.payload.commentId 
          state.commentIdsOfRepliesLoaded.push(action.payload.commentId)

          state.commentIdReplies = action.payload.commentId
          state.commentRepliesLoading = false
          state.commentRepliesSuccess = true

          let newObj = {
            commentId: action.payload.commentId,
            index: action.payload.index,
            loading: false,
            status: "success",
          }

          state.commentIndexLoading = newObj
          state.eventDiscussionComments[action.payload.index].replies = action.payload.replies

        })
        .addCase(getEventDiscussionCommentReplies.rejected, (state, action) => {
          state.commentRepliesLoading = false
          state.commentRepliesSuccess = false
        })
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(createCalendarItem.pending, (state) => {
          state.createCalendarItemStatus = STATUS.LOADING
        })
        .addCase(createCalendarItem.fulfilled, (state, action) => {
          state.createCalendarItemStatus = STATUS.SUCCESS
        })
        .addCase(createCalendarItem.rejected, (state, action) => {
          state.createCalendarItemStatus = STATUS.ERROR
        })
    },
})

export const { 
  resetEvent, setViewEventModalEdit, hideEventModal, 
  setViewEventModal, setEventImagePreview, chooseEventImage, 
  removeEventImage, setEventFilter, setSinglePageEventData,  
  setLoadingCommentIdReplies, setDateSelectedFromCalendar, 
  setViewRsvpModal, setRsvpData, setViewCalendarItemInfoModal,
  setViewAddCalendarItemModal
} = eventSlice.actions
export default eventSlice.reducer