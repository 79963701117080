import PostItemFilter from 'components/postComponents/PostItemFilter'
import PostItem from 'components/PostItem'
import React from 'react'
import { useState, useEffect, useRef}  from 'react' 
import { useSelector, useDispatch } from 'react-redux'
import {  
    postReset, getProfilePosts, setViewCreatePostModal, setViewSendQuickMessageModal,
    postResetSaved, resetPostLoadingState, setViewWhoIsPrayingModal
} from 'features/posts/postSlice' 
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { toast } from 'react-toastify' 
import { setConfirmModalData, setViewConfirmModal } from 'features/modal/modalSlice'
import ConfirmModal from 'components/modals/ConfirmModal'  
import useGetWindowSize from 'hooks/useGetWindowSize'
import AddIcon from '@mui/icons-material/Add';
import PrayerWallButton from 'components/sidebar/PrayerWallButton'
import SearchPosts from 'components/postComponents/searchPosts/SearchPosts'
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import NoPosts from 'components/postComponents/NoPosts'
import NoMembers from 'components/postComponents/NoMembers'
import SpinnerIntermintentSmall from 'loaders/SpinnerIntermintentSmall'
import { Modal } from '@mui/material'
import WhoIsPrayingModal from './WhoIsPrayingModal'
import SendQuickMessageModal from './SendQuickMessageModal'
import { ComponentPageWrapper } from 'pages/pages.styled'

export default function Posts({ church }) {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { 
        posts, isPostLoading, currentPage, viewWhoIsPrayingModal, viewSendQuickMessageModal,
        hasNextPage, nextPage, filterValue, isPostDeletedSuccess, isPostSaved 
    } = useSelector((state) => state.posts)
    const { churchName } = useParams() 
    const { viewConfirmModal, confirmModalData } = useSelector((state) => state.modal)
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const [viewMobileSearch, setViewMobileSearch] = useState(false)
    const [width, height] = useGetWindowSize()
    const [resultsLimit, setResultsLimit] = useState(10)
    const refScrollFeed = useRef(null)

    useEffect(() => {
        if(isPostDeletedSuccess){
            dispatch(setConfirmModalData({}))
            dispatch(setViewConfirmModal(false))  
            dispatch(resetPostLoadingState({
                valuesToResetArray: ['isPostDeletedSuccess', 'isPostDeletedLoading']
            }))
        }
    }, [isPostDeletedSuccess, dispatch])

    useEffect(() => {
        if(isPostSaved){
          toast.success('Post Saved',
            {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          })
          dispatch(postResetSaved())
        }
      }, [isPostSaved, dispatch])

    useEffect(() => { 
        if(church){  
            dispatch(getProfilePosts({ 
                churchName: churchName,
                currentPage: currentPage,
                resultsLimit: resultsLimit,
                filter: filterValue
            }))
        }
    }, [])

    const handleScroll = (e) => { 
        let bottom = ((Math.ceil(e.target.scrollHeight - e.target.clientHeight).toFixed(0)) - e.target.scrollTop) < 20
        if (bottom) {
            handleLoadMore()
        }
    }

    const handleLoadMore = () => {  
        if(hasNextPage && !isPostLoading){
            dispatch(getProfilePosts({
                churchName: churchName,
                currentPage: Number(nextPage),
                filter: filterValue,
                resultsLimit: resultsLimit
            }))
        }
    }

    useEffect(() => {
        return () => {
            dispatch(postReset())
        }
    },[])
 
    return (
        <ComponentPageWrapper> 
            <TopPostItemsContainer>
                <Side display= {'flex'}>
                    <PostItemFilter 
                        style={{zIndex: 33}}
                        />
                    { width < 768 ? (
                            <Side2>
                                <CreatePostBtnMPT1 onClick={() => dispatch(setViewCreatePostModal(true))}> 
                                    <AddIcon /> <span>New Post</span>
                                </CreatePostBtnMPT1>

                                { viewMobileSearch ? (
                                        <SearchPosts />
                                    )
                                    :
                                    (
                                        <MobileSearchButton onClick={() => setViewMobileSearch(true)}>
                                            <ManageSearchIcon />
                                        </MobileSearchButton>
                                    )
                                } 
                            </Side2>
                        )
                        :
                        (
                            <SearchPosts />
                        )
                    } 
                </Side>
                <Side display= {width < 768 ? 'none' : 'flex'}> 
                    <PrayerWallButton /> 
                </Side> 
            </TopPostItemsContainer>

            <PostFeedContainer
                ref={refScrollFeed}
                onScroll={handleScroll}
                >
                {
                    (posts && posts.length > 0) ?  
                    (
                        posts.map((post, index) => (
                            
                            post.user !== null &&
                            <PostItem 
                                key={post._id}
                                index={index}
                                post={post}
                                canViewWhoisPraying={user.isOrganization || post.user._id === user._id}
                                postId={post._id}
                                /> 
                        )) 
                    )
                    : (!isPostLoading && posts && !filterValue && posts.length === 0) ?
                    (
                        user.isOrganization ? 
                        (
                            profileChurch && profileChurch.members.length === 0 ? 
                            (
                                <NoMembers 
                                    key={Date.now() + 123342}
                                    /> 
                            )
                            : profileChurch && profileChurch.members.length > 0 &&
                            (
                                <NoPosts 
                                    key={Date.now() + 123}
                                    />
                            )
                        )
                        : 
                        (
                            <NoPosts 
                                key={Date.now()}
                                />
                        )
                    )
                    : isPostLoading && (
                        <div
                            style={{marginTop: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: '100%', width: '100%'}}
                            >
                          <SpinnerIntermintentSmall 
                            color='var(--themeColor)'
                            size={'50px'} 
                            />
                        </div>
                    )
                }
            </PostFeedContainer>

            <Modal
                open={viewConfirmModal}
                onClose={() => dispatch(setViewConfirmModal(false))}
                aria-labelledby="modal-modal-title"
                >
                <ModalWrapper1>
                    <ConfirmModal 
                        text={confirmModalData.text} 
                        handler={confirmModalData.handler} 
                        confirmBtnBackgroundHover={confirmModalData.confirmBtnBackgroundHover}
                        extraInfo={confirmModalData.extraInfo}
                        conditionToCloseModal={isPostDeletedSuccess}
                        />                
                </ModalWrapper1>

            </Modal>
            <Modal
                open={viewWhoIsPrayingModal}
                onClose={() => dispatch(setViewWhoIsPrayingModal(false))}
                aria-labelledby="modal-modal-title"
                >
                <ModalWrapper1>
                    <WhoIsPrayingModal />            
                </ModalWrapper1> 
            </Modal>
            <Modal
                open={viewSendQuickMessageModal}
                onClose={() => dispatch(setViewSendQuickMessageModal(false))}
                aria-labelledby="modal-modal-title"
                >
                <ModalWrapper1>     
                    <SendQuickMessageModal />
                </ModalWrapper1> 
            </Modal>
        </ComponentPageWrapper>
    )
}

const ModalWrapper1 = styled.div`   
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const TopPostItemsContainer = styled.div`
    width: 100%;  
    z-index: 10;
    position: sticky;
    background-color: var(--themeBGColor);
    top: 0;
    display: flex;
    flex-direction: row; 
    height: fit-content;
    align-items: center; 
    justify-content: space-between;
    padding: 10px 10px 10px 10px;
    @media (max-width: 768px) {
        padding: 5px;
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
        padding: 0px;
    
    }
`

const Side = styled.div`
    display: ${props => props.display};
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: fit-content;
    width: fit-content;


    @media (max-width: 768px) {
        width: 100%;
        justify-content: flex-end;
    }
`

const Side2 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: fit-content;
    width: fit-content;

    @media (max-width: 768px) {
        width: 100%;
        justify-content: flex-end;
    }
`

const MobileSearchButton = styled.div`
    height: 35px;
    padding: 5px;
    background-color: var(--themeColor);
    width: fit-content; 
    white-space: nowrap;
    font-size: 11px;
    color: white;
    border-radius: 10px;
    margin-left: 10px;
    
`

const CreatePostBtnMPT1 = styled.div`
    display: none;
    color: white;
    border-radius:10px;
    height: 35px;
    padding: 5px;
    background-color: var(--themeColor);
    width: fit-content; 
    white-space: nowrap;
    font-size: 11px;

    svg{
        font-size: 16px;
    }

    span{
        margin-right: 5px;
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: row;
        align-items: center; 
    }
`

const PostFeedContainer = styled.div` 
    padding: 00px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 10px;
    width: 100%;   
    /* 
        
        header height: 50px
        church profile top info height: 150px
        filter height: 50px + 30px padding

    */
    height: calc(100% - 70px);
    /* height: 100%; */
    overflow-y: scroll;
    /* remove scroll bar width */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
    z-index: 4;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    @media (max-width: 768px) {
        padding: 0px;
        height: calc(100% - 50px);
        /* height: calc(100% - 205px); */
    }
`
