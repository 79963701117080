import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl
 

const config = (token) => { return { 
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}`, }, 
  } }
  

const sendEmailInvite = async (data, token) => {
    const response = await axios.post(URL+'/api/email/sendEmailInvite', data, config(token))
    return response.data
}

const sendGivingTransactionEmail = async (data, token) => {
    const response = await axios.post(URL+'/api/email/sendGivingTransactionEmail', data, config(token))
    return response.data
}

const bulletinService = {
    sendEmailInvite , sendGivingTransactionEmail
}

export default bulletinService