import React  from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { setViewBulletinFile  } from 'features/bulletin/bulletinSlice' 
import { useSelector } from 'react-redux'; 
import { setConfirmModalData, setViewConfirmModal } from 'features/modal/modalSlice';
import { deleteBulletin } from 'features/bulletin/bulletinSlice';
import { useRef } from 'react'
import { Tooltip } from '@mui/material';
import { RxDownload } from "react-icons/rx"; 
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useToggleDropdown from 'hooks/useToggleDropdown';
import { DotEle, DotsWrap } from 'components/dots/dots.styled';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ArticleIcon from '@mui/icons-material/Article';

export default function BulletinItem(props) {

    const dispatch = useDispatch()
    const { user, admin } = useSelector(state => state.auth)
    const ref = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref)
    const handleDropDown = () => { setIsDropped.toggle(ref) }
    const { profileChurch } = useSelector(state => state.profileChurch)

    const handleView = () => {

        dispatch(setViewBulletinFile(props.data.path))
    }

    const deleteBulletinFile = () => {
        dispatch(deleteBulletin({
            index: props.index,
            id: props.data._id,
            fileName: props.data.fileName
        }))

        dispatch(setViewConfirmModal(false))
    }

    const handleDelete = () => {

        dispatch(setConfirmModalData({ 
            text: `Are you sure you want to delete ${props.data.fileName}?`,
            handler: deleteBulletinFile,
            confirmBtnBackgroundHover: 'red',
        }))

        dispatch(setViewConfirmModal(true))

    }

    const handleDownloadBulletin = async () => {

        let bulletinUrl = `https://api.praysely.com/static/bulletin/${props.data.fileName}`

        fetch(bulletinUrl).then(response => {
            response.blob().then((blob) => {  
                const fileURL = window.URL.createObjectURL(blob); 
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = props.data.fileName;
                alink.click();
            });
        })

    }


    return (

        <BulletinItemContainer  >
                <section onClick={handleView}>
                    <AISection >
                        <ArticleIcon style={{ marginRight: '10px' }} />
                        <div>{props.data.fileName}</div>                 
                    </AISection> 
                </section>

                <AISection>
                    <Tooltip title="View" placement='top'>
                        <IconButton
                            onClick={handleView}
                            >
                            <OpenInFullIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Download" placement='top'>
                        <IconButton
                            onClick={handleDownloadBulletin}
                            download
                            >
                            <RxDownload style={{backgroundColor: 'inherit'}} />
                        </IconButton>
                    </Tooltip>

                    {(admin[profileChurch._id] || user.isOrganization) && ( 
                        <>
                            <IconButton
                                ref={ref}
                                onClick={handleDropDown}
                                >
                                <MoreHorizIcon />
                            </IconButton> 
                            {
                                isDropped &&
                                (
                                    <DotsWrap
                                        top="5px"
                                        right="20px"
                                        >
                                        <DotEle
                                            onClick={handleDelete}
                                            >
                                            <DeleteIcon style={{marginRight: '10px', fontSize: '15px'}} />
                                            Delete</DotEle>
                                        <DotEle
                                            // onClick={handleEdit}
                                            >
                                            <EditIcon style={{marginRight: '10px', fontSize: '15px'}} />
                                            Edit</DotEle>
                                    </DotsWrap>
                                )
                            } 
                        </> 
                    )} 
                </AISection>  
        </BulletinItemContainer>
    )
}
 

const IconButton = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    outline: none; 
    border: none;
    svg{
        font-size: 20px;
        color: var(--lightThemeFontColor);
        background-color: inherit;
    }
    &:hover{
        transition: all 0.5s;
        
        background-color: #dfdfdf;
    }
`

const AISection = styled.span`
    position: relative;
    padding: 0 10px; 
    display: flex; 
    flex-direction: row;
    align-items: center;
    justify-content: center;

`
const BulletIconWrap = styled.div`
    color: #797979;
    margin-right: 10px;
    @media (max-width: 800px) {

        display: none;

    }

`

const BulletinItemContainer = styled.div`
    color: var(--lightThemeFontColor);
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    margin: 5px 0;
    /* background-color: #c2c2c2; */
    border-radius: 5px;
    /* box-shadow: 0px 0px 5px #ccc; */
    border: 1px solid #dfdfdf;

    section{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    &:hover{
        cursor: pointer;    
        border: 1px solid #ccc;
    }


`
const BIInfo = styled.div`
    color: var(--lightThemeFontColor);

`

const BIC2V = styled.div`
    position: absolute;
    color: grey;
    top: 5px;
    right: 32px;
    font-size: smaller;
    display: flex;
    flex-direction: row;
    align-items: center;


`

const BIDetails = styled.div`
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: smaller;
    color: grey;
    font-style: italic;
`
const DeleteBulletin = styled.div`
    margin-left: 15px;
    padding: 5px;
    color: #888888;
    /* border: 1px solid #ff00009d; */
    border-radius: 4px;
    background-color: none;
    cursor: pointer;
    &:hover{
        background-color: red;
        border: 2px solid red;
        color: white
    }
`