import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import prayerWallService from './prayerWallService'
import { STATUS } from 'features/enums'

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

const SIDEBAR = Object.freeze({
    none: 'none',
    prayerWall: 'prayerWall',
    events: 'events',  
})

const initialState = {

    prayerWallItems: [], 
    getPrayerWallItemsStatus: STATUS.NOT_STARTED, 
    getEventItemsStatus: STATUS.NOT_STARTED,  
    viewSidebar: localStorage.getItem('viewSidebar') ? localStorage.getItem('viewSidebar') : SIDEBAR.none,

    eventItem: null,
    eventsList: [],

}

 
export const getPrayerWallItems = createAsyncThunk(
    'prayerWall/getPrayerWallItems',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await prayerWallService.getPrayerWallItems(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const addItemToPrayerWall = createAsyncThunk(
    'prayerWall/addItemToPrayerWall',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await prayerWallService.addItemToPrayerWall(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)




export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setViewSidebar: (state, action) => {
            state.viewSidebar = action.payload
            localStorage.setItem('viewSidebar', action.payload)
        },
        setEventItem: (state, action) => {

            console.log(action.payload) 

            state.eventItem = {
                name: action.payload.name,
                start: action.payload.start.toString(),
                end: action.payload.end.toString(), 
            }
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getPrayerWallItems.pending, (state) => {
                state.isGetPrayerWallItemsLoading = true
                state.isGetPrayerWallItemsSuccess = false
            })
            .addCase(getPrayerWallItems.fulfilled, (state, action) => {
                state.isGetPrayerWallItemsLoading = false
                state.isGetPrayerWallItemsSuccess = true

                state.prayerWallItems = action.payload

            })
            .addCase(getPrayerWallItems.rejected, (state, action) => {
                state.isRequestGetLoading = false
                state.isRequestGetError = true
                state.requestGetmessage = action.payload
            }) 
    }
})

export const { setViewSidebar, setEventItem } = sidebarSlice.actions
export default sidebarSlice.reducer
