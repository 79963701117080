import axios from 'axios'
import { baseUrl } from '../constants'
const URL = baseUrl

const URL_GET = '/api/event/getEvents'
const URL_CREATE = '/api/event/createEvent'
const URL_RESPONSE = '/api/event/respond'
const URL_SAVE = '/api/event/save'
const URL_DELETE  = '/api/event/delete'
const URL_UPDATE  = '/api/event/update'
const URL_GET_SAVED  = '/api/event/getSavedEvents'
const URL_GET_EVENT_DISCUSSION_COMMENTS  = '/api/event/getEventDiscussionComments'
const URL_DELETE_EVENT_DISCUSSION_COMMENT  = '/api/event/deleteEventDiscussionComment'
const URL_GET_SINGLE_EVENT_DATA  = '/api/event/getSingleEventData'

//for sending the token
const config = (token) => { return { 
  withCredentials: true,
  headers: { Authorization: `Bearer ${token}`, }, 
} }

//---------------------------------------------------------------

const getEvents = async (data, token) => {
  const response = await axios.post(URL+URL_GET, data, config(token))
  return response.data
}

const createEvent = async (data, token) => {
    const response = await axios.post(URL+URL_CREATE, data, config(token))
    return response.data
  }
  
const createCalendarItem = async (data, token) => {
    const response = await axios.post(URL+'/api/event/createCalendarItem', data, config(token))
    return response.data
  }


  const updateEvent = async (data, token) => {
    const response = await axios.post(URL+URL_UPDATE, data, config(token))
    return response.data
  }

const sendResponse = async (data, token) => {
    const response = await axios.post(URL+URL_RESPONSE, data, config(token))
    return response.data
  }

const saveEvent = async (data, token) => {
    const response = await axios.post(URL+URL_SAVE, data, config(token))
    return response.data
  }

  const deleteEvent = async (data, token) => {
    const response = await axios.post(URL+URL_DELETE, data, config(token))
    return response.data
  }

  const getSavedEvents = async (data, token) => {
    const response = await axios.post(URL+URL_GET_SAVED, data, config(token))
    return response.data
  }

  const getEventDiscussionComments = async (data, token) => {
    const response = await axios.post(URL+URL_GET_EVENT_DISCUSSION_COMMENTS, data, config(token))
    return response.data
  }

  const getEventDiscussionCommentReplies = async (data, token) => {
    const response = await axios.post(URL+'/api/event/getEventDiscussionCommentReplies', data, config(token))
    return response.data
  }

  const deleteEventDiscussionComment = async (data, token) => {
    const response = await axios.post(URL+URL_DELETE_EVENT_DISCUSSION_COMMENT, data, config(token))
    return response.data
  }

  const getSingleEventData = async (data) => {
    const response = await axios.post(URL+URL_GET_SINGLE_EVENT_DATA, data)
    return response.data
  }

  const getSingleEventDataLoggedIn = async (data, token) => {
    const response = await axios.post(URL+'/api/event/getSingleEventDataLoggedIn', data, config(token))
    return response.data
  }

  const addEventDiscussionComment = async (data, token) => {
    const response = await axios.post(URL+'/api/event/addEventDiscussionComment', data, config(token))
    return response.data
  }

  const addEventDiscussionReply = async (data, token) => {
    const response = await axios.post(URL+'/api/event/addEventDiscussionReply', data, config(token))
    return response.data
  }



const eventService = {
    getEvents, createEvent, sendResponse, saveEvent, deleteEvent, updateEvent,  createCalendarItem,
    getSavedEvents, getEventDiscussionComments, deleteEventDiscussionComment, getSingleEventDataLoggedIn,
    getSingleEventData, addEventDiscussionComment, addEventDiscussionReply, getEventDiscussionCommentReplies
}

export default eventService