import { useEffect, useState } from 'react'
import { toast } from 'react-toastify' 
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import EventFeed from './EventFeed'
import CreateEventModal from './CreateEventModal'
import { 
    setViewEventModal, 
    hideEventModal,
    setViewAddCalendarItemModal, 
    setViewCalendarItemInfoModal, 
} from '../../features/events/eventSlice' 
import CreateButton from 'components/buttons/CreateButton'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import Calendar from './calendar/Calendar'
import { Modal } from '@mui/material'
import { ComponentPageWrapper } from 'pages/pages.styled'
import { MobilePageTitle } from 'components/main.styled'
import { language } from 'app/language'
import { Toaster } from 'react-hot-toast';
import { FaQuestionCircle } from "react-icons/fa";
import Tooltip from '@mui/material/Tooltip';  
import CreateCalendarItem from './calendar/CreateCalendarItem' 

export default function Events() {
 
    const dispatch = useDispatch() 
    const { user, admin, moderator} = useSelector((state) => state.auth)
    const {  isError, message  } = useSelector((state) => state.profile)
    const { profileChurch } = useSelector((state) => state.profileChurch)  

    const [ eventView , setEventView ] = useState( localStorage.getItem('eventView') ?? 'items'  ) // ['items', 'calendar', 'past']

    useEffect(() => {
        if(isError){toast.error(message)} 
    }, [])
 
    const { 
        viewEventModal, viewEventModalEdit, 
        eventEditData, isEventLoading, eventImagePreview, 
        selectedImage, eventEditIndex,
        viewCalendarItemInfoModal, viewAddCalendarItemModal
    } = useSelector((state) => state.events)

    const handleCreateEvent = () => {
        dispatch(setViewEventModal())
    }

    const handleViewCalendar = () => {
        if(eventView === 'items'){
            setEventView('calendar')
            localStorage.setItem('eventView', 'calendar')
        }else{
            setEventView('items')
            localStorage.setItem('eventView', 'items')
        }
    }

        const handleCreateCalendarItem = () => {
            dispatch(setViewAddCalendarItemModal(true))
    
        }
    
        const handleInfoClick = () => {
    
            dispatch(setViewCalendarItemInfoModal(true))
    
        }

    return (
        
        <ComponentPageWrapper>
            <Toaster />
            <MobilePageTitle>
                {language.events[user.preferredLanguage]}
            </MobilePageTitle>

            <EventsTop>


                <CreateButton 
                    customIcon={eventView === 'items' ? <CalendarMonthIcon/> : <CalendarViewMonthIcon/>}
                    handler={handleViewCalendar} 
                    buttonText={eventView === 'items' ? language.viewCalendar[user.preferredLanguage] : language.viewItems[user.preferredLanguage]}
                    />

                {(user.isOrganization || admin[profileChurch._id]) && (
                    <div style={{height: 'fit-content',display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', width: 'fit-content'}}>
                        <CreateButton 
                            handler={handleCreateEvent}
                            buttonText={'New Event'}
                            />     
                        <CalItemContainer>
                            <CreateButton   
                                handler={handleCreateCalendarItem}
                                buttonText='Calendar Item'
                                />         
            
                                <Tooltip title="What is a Calendar Item?">
                                    <SyncCalendar
                                        onClick={handleInfoClick}
                                        >
                                        <FaQuestionCircle />
                                    </SyncCalendar>
                                </Tooltip> 
            
                        </CalItemContainer>            
                    </div>

                ) }
                        
            </EventsTop>
            
            {
                eventView === 'items' ? (
                    <EventFeed/>
                ) : (
                    <Calendar />
                )
            }
            
            <Modal
                open={viewEventModalEdit}
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                onClose={() => dispatch(hideEventModal())}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <div
                    style={{ 
                        width: '100%',
                        height: '100%',
                        maxHeight: '80vh', 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',  
                    }}  
                    >
                <CreateEventModal
                    isEditing={true}
                    data={eventEditData}
                    index={eventEditIndex}
                    />
                </div>
            </Modal>


            <Modal  
                open={viewEventModal}
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                onClose={() => dispatch(hideEventModal())}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <div
                    style={{ 
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center', 
                        padding: '10px'
                    }}  
                    >
                       <CreateEventModal /> 
                    </div>
                    
            </Modal> 
            <Modal
                open={viewCalendarItemInfoModal}
                onClose={() => {
                    dispatch(setViewCalendarItemInfoModal(false))
                }}
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                
                
                <MCVGFN>
                    <h1>
                    <FaQuestionCircle />
                    </h1>
                    <p>
                        Use this feature for "ongoing" events where you don't necessarily need a discussion, image, and/or rsvp.
                        For example, you can create a calendar item for a specific 
                        church service that occurs on a weekly or monthly basis.
                        You can also create intraday events with a specfic time, or even an all day event!
                    </p>
                </MCVGFN> 
            </Modal> 
            <Modal
                open={viewAddCalendarItemModal}
                onClose={() => {
                    dispatch(setViewAddCalendarItemModal(false))
                }}
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <CreateCalendarItem /> 
            </Modal> 
        </ComponentPageWrapper> 
    )
}


const EventsTop = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;  

    @media (max-width: 768px) { 
        width: 100%;
        margin-bottom: 0px;
        padding: 10px;
        /* margin-top: 10px; */
        align-items: center;
        justify-content: flex-start;
    }
` 
const MCVGFN = styled.div`
width: 500px;
height: 400px;
display: flex;
flex-direction: column;
align-items: center;
background-color: white;
justify-content: center;
padding: 20px;
color: var(--lightThemeFontColor);
border-radius: 10px;

`


const CalItemContainer  = styled.div` 
    width: fit-content;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; 
`

const SyncCalendar = styled.div` 
    display: flex;
 
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg{
        
        font-size: 20px;
        color: var(--lightThemeFontColor);
    }
`