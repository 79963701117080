import styled from "styled-components";

export const ExtrasContainer = styled.div`
    width: fit-content;
    height: calc(100vh - 60px);
    display: flex;
    justify-self: flex-start;
    flex-direction: column;
    padding: 0 4px;
    color: rgb(86, 86, 86);
    border-right: 1px solid rgb(200, 200, 200);
    @media (max-width: 768px) {
        display: none;
    }
`
export const ExtrasItem = styled.span`
    padding: 5px 20px;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    width: fit-content; 
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px; 
`   

export const ExtrasList = styled.div`
    margin-top: 10px;
    padding: 7px 15px;
    padding-left: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    color: var(--lightThemeFontColor);
    transition: all 0.3s;
    width: fit-content;
    position: relative;


    &:hover{
        background-color: #E0E0E0;
        border-radius: 25px;
        width: fit-content;
        color: #424242;  
    }

`


export const ExtrasIcon = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    padding: 12px;
    border-radius: 50%;
    background-color: rgb(252, 252, 252);
    position: relative;
    svg{
        font-size: 16px; 
    }
`


export const PCContainer = styled.div `
    z-index: 122;
    margin-left: 0px;
    margin-top: 5px;
    padding: 10px;
    display: flex;
    width: 100%;
    height: fit-content;    
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 1px solid rgb(200, 200, 200);
    @media (max-width: 768px) {
        padding: 0px;
        width: 100%;
        margin-left: 0;
        height: fit-content;
    }
`  
export const AccountVerified = styled.div`
    width: 100%;
    height: 30px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #2ba32070;
    border-radius: 5px;
    font-size: small;
    margin-bottom: 10px;
`
export const PCTNameWrap = styled.div`
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--lightThemeFontColor);

    @media (max-width: 768px) {
        padding: 0px;
        margin-left: 10px;
 
        span{
            font-size: 11px;
            max-width: 220px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`
export const NavWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
`

export const MenuWrap = styled.div`
    display: flex; 
    flex-direction: row;
    align-items: center; 
    align-self: flex-end; 
    padding-right: 10px;
`

export const MainItems = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; 
`

export const PCTopInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;    
    align-items: flex-start;
    @media (max-width: 768px) {
        padding: 0px 10px;
    }
` 
export const PCName = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: rgb(50,50,50);
    @media (max-width: 768px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%; 
        padding: 0px;
    }
`  
export const PCNav = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: -10px; 
    margin-top: 3px;
    z-index: 2;
    width: 100%; 

    @media (max-width: 768px) {
        margin-left: 0;
        width: fit-content; 
        margin-top: 8px;
        margin-bottom: 8px; 
        align-items: flex-start;
        justify-content: flex-end;
    }
` 
export const PCNavLiTitleIcon = styled.div` 
    @media (max-width: 768px) {
        display: unset;
    }
`
export const PCNavLiTitle = styled.div`
    padding-left: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    /* @media (max-width: 768px) {
        display: ${props => props.show ? ('unset'):('none') };
    }  */
`
export const PCNavLi = styled.div`
    margin-top:  ${props => props.active ? ('0px'):('3px') };
    padding: 5px 15px;
    cursor: pointer; 
    border-radius:  ${props => props.active ? ('0px 0px 7px 7px'):('7px') };
    margin-left: 5px;
    color:  ${props => props.active ? ('white'):('#676767') };
    background-color:  ${props => props.active ? ('var(--themeColor)'):('none') };
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    position: relative;


    &:hover{ 
        margin-top: 0;
        background-color:  ${props => props.active ? ('var(--themeColor)'):('#d3d3d3') };
        color: ${props => props.active ? ('white'):('#676767') };
        border-radius:  ${props => props.active ? ('0px 0px 7px 7px'):('7px') }; 
    }

    @media (max-width: 768px) {
        width: fit-content;
        flex: 1;
        padding: 2px 8px;
    }
`  
export const PCNavLiDropDown = styled.div`
    position: absolute;
    top: 50px;
    left: 0px; 
    background-color: white; 
    padding: 5px;
    z-index: 5;
    color: grey;
    width: 175px;
    height: fit-content;
    box-shadow: 2px 2px 2px rgb(179, 179, 179); 
    @media (max-width: 768px) { 

        left: -110px;
        border-radius: 10px 0px 10px 10px;
    }
`
export const DropDownItem = styled.div` 
    width: 100%;
    padding: 10px;
    cursor: pointer;
    z-index: 5;
    overflow: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;

    justify-content: flex-start; 
    span{
        margin-left: 10px;
    }
    &:hover{
       background-color: rgb(238, 238, 238); 
    }
    @media (max-width: 768px) {  
        padding: 15px;
    }
`
export const PCNavLiMore = styled.div`
    margin-top:  ${props => props.active ? ('0px'):('3px') };
    padding: 5px 15px;
    cursor: pointer; 
    border-radius:  ${props => props.active ? ('0px 0px 7px 7px'):('12px') };
    margin-left: 5px;
    color:  ${props => props.active ? ('white'):('#555555') };
    background-color:  ${props => props.active ? ('var(--themeColor)') : props.bgColor ? props.bgColor:('none') };
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    position: relative;
    z-index:0;
    background-color: rgb(220,220,220);


    &:hover{  
        transition: 0.5s;
        border-radius: 10px;
        background-color: rgb(200,200,200);
    }

    @media (max-width: 768px) {
        width: fit-content;
        flex: 1;
    }
`  

export const PCNavMenuIcon = styled.div`
    margin-top:  ${props => props.active ? ('0px'):('3px') };
    padding: 5px 15px;
    cursor: pointer; 
    border-radius: 0px 0px 7px 7px;
    margin-left: 5px;
    color:  ${props => props.active ? ('white'):('#555555') };
    background-color:  ${props => props.active ? ('var(--themeColor)') : props.bgColor ? props.bgColor:('none') };
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    position: relative;
    z-index:0;
    background-color: rgb(220,220,220);

    &:hover{  
        transition: 0.5s;
        background-color: rgb(200,200,200);
    }

    @media (max-width: 768px) {
        width: fit-content;
        flex: 1; 
    }
`