import React, { useEffect } from 'react'
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { getPrayerWallItems, setViewSidebar } from 'features/sidebar/sidebarSlice'; 
import PrayerWallFeed from './PrayerWallFeed';
import EventsFeed from './EventsFeed';


export default function Sidebar(props) {

    const dispatch = useDispatch()
    const { 
        viewSidebar,  
    } = useSelector((state) => state.sidebar)
    const { user } = useSelector((state) => state.auth)
    const { profileChurch } = useSelector((state) => state.profileChurch)

    const handleClickClose = () => {
        dispatch(setViewSidebar("none"))
    }


    useEffect(() => {
        if(viewSidebar === 'prayerWall'){
            dispatch(getPrayerWallItems({
                churchId: profileChurch._id
            })) 
        } 
    }, [viewSidebar])


    return (
        <SidebarContainer  
            width={viewSidebar !== "none" ? "300px" : "0px"}
            > 
            { viewSidebar !== "none" &&  
                (
                    <SidebarWrapper>
                        <PrayerWallHeader>
                            <h2> 
                                {
                                    viewSidebar === "prayerWall" ? 
                                    (
                                        'Prayer Wall'
                                    )
                                    : viewSidebar === "events" && (
                                        'Events'
                                    )
                                }
                            </h2>
                            <CloseButton onClick={handleClickClose} > <CloseIcon style={{fontSize: '16px'}}/> </CloseButton>
                        </PrayerWallHeader>       

                        {
                            viewSidebar === "prayerWall" ? ( 
                                <PrayerWallFeed />
                            ) 
                            : viewSidebar === 'events' && (
                                <EventsFeed />
                            )
                        }

                    </SidebarWrapper> 
                ) 
            } 
        </SidebarContainer>
    )
}

const SidebarContainer = styled.div` 
    height: 100%; 
    width: ${props => props.width};
    padding: ${props => props.width !== "0px" ? "10px" : "0px"};
    transition: all 0.5s ease-in-out;
    @media (max-width: 768px) {
        display: none;
    }
`

const SidebarWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: var(--lightThemeBackground);
`
 
const PrayerWallHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h2{
        font-weight: 300;
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        color: var(--lightThemeFontColor);        
    }
`

const CloseButton = styled.div`
    cursor: pointer;
    display: flex;
    color: var(--lightThemeFontColor);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    transition: all 0.5s ease-in-out;
    &:hover{
        background-color: #c4c4c4;
        color: white;
    }
`