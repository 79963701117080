import React, { useEffect, useState, useRef } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import styled from 'styled-components'   
import { useSelector, useDispatch } from 'react-redux'
import Tooltip from '@mui/material/Tooltip';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'; 
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'; 
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'
import { IoRemoveCircleOutline } from "react-icons/io5";
import { ButttonWrap } from 'components/files/files.styled'
import { SubmitButton } from 'components/giving/giving.styled'  
import SpinnerSmall from 'components/SpinnerSmall'
import { createCalendarItem } from 'features/events/eventSlice'
import { VscChromeClose } from "react-icons/vsc";

const dateInputStyle = {
    borderColor: "white",
    width: "100%",
    input: { color: '#868686' },
    "& .MuiInputLabel-root.Mui-focused": { 
      color: "#656565" 
    }, //styles the label
    "& .MuiOutlinedInput-root": {
     "&:hover > fieldset": { borderColor: "white" },
     height: "48px",
     borderRadius: "10px",
     backgroundColor: 'aliceblue',
     borderColor: "white"
    },
    ".MuiOutlinedInput-notchedOutline":{
      border: "none",
    }
  }

const timeInputStyle = {
    borderColor: "white",
    width: "200px",
    input: { color: '#868686' },
    "& .MuiInputLabel-root.Mui-focused": { 
      color: "#656565" 
    }, //styles the label
    "& .MuiOutlinedInput-root": {
     "&:hover > fieldset": { borderColor: "white" },
     height: "48px",
     borderRadius: "10px",
     backgroundColor: 'aliceblue',
     borderColor: "white"
    },
    ".MuiOutlinedInput-notchedOutline":{
      border: "none",
    }
}

export default function CreateCalendarItem() {



    const dispatch = useDispatch() 
    const eventNameRef = useRef(null)
    const { createCalendarItemStatus } = useSelector((state) => state.events)
    const [format, setFormat] = useState('day') // custom, consistent dates
    const [breadCrumb, setBreadCrumb] = useState('dates') // selectDates, selectTimes
    const [selectedDates, setSelectedDates] = useState([])
    const [selectedTimes, setSelectedTimes] = useState([])
    const [openStartTime, setOpenStartTime] = useState(false) 
    const [openEndTime, setOpenEndTime] = useState(false)
    const [openStart, setOpenStart] = useState(false) 
    const [startDate, setStartDate] = useState(dayjs( `${dayjs().year()}-${dayjs().month() + 1}-${dayjs().date()}`));

    const [startTime, setStartTime] = useState( dayjs( `2024-01-17T15:30`));
    const [endTime, setEndTime] = useState( dayjs( `2024-01-17T16:30`));

    const handleDate = (date) => { 
        let dateString = date.toString().split(' ')
        let dateStr = `${dateString[1]} ${dateString[2]} ${dateString[3]}` 
        setSelectedDates([...selectedDates, dateStr]) 
    }

    const removeDateFromList = (date) => {
        let newDates = selectedDates.filter(d => d !== date)
        setSelectedDates(newDates)
    }

    const handleTime = (time) => {
        setSelectedTimes({
            ...selectedTimes,
            time
        })
    }

    const handleSubmit = () => { 
        let startTime0 = `${startTime.get('hour')}:${startTime.get('minute')}`
        let endTime0 = `${endTime.get('hour')}:${endTime.get('minute')}`
        let uniqueDates = [...new Set(selectedDates)] 
        dispatch(createCalendarItem({
            eventName: eventNameRef.current.value,
            startTime: startTime0,
            endTime: endTime0,
            selectedDates: JSON.stringify(uniqueDates), 
        })) 
    }

    return (
        <CreateCalendarItemForm>
            <TopBar> <h2>Create Calendar Item</h2> </TopBar>

            <EventNameInput 
                ref={eventNameRef}
                placeholder='Event Name'
                 /> 

                <SlctCont>
                    <h2>Select Occurance(s)</h2>
                    <BxItemCont>
                        <LocalizationProvider dateAdapter={AdapterDayjs}> 
                        <DesktopDatePicker
                            value={startDate}
                            onChange={handleDate}
                            sx={dateInputStyle}
                            open={openStart}
                            onClose={() => setOpenStart(false)}
                            slotProps={{textField: {onClick: () => setOpenStart(true)},}} 
                            />
                        </LocalizationProvider>
                    </BxItemCont>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                        <BxItemCont2>
                            <h2>Select Start Time</h2> 
                            <LocalizationProvider dateAdapter={AdapterDayjs}> 
                            <DesktopTimePicker
                                value={startTime}
                                onChange={(newValue) => setStartTime(newValue)}
                                sx={timeInputStyle}
                                defaultValue={dayjs('2022-04-17T15:30')} 
                                open={openStartTime}
                                onClose={() => setOpenStartTime(false)}
                                slotProps={{textField: {onClick: () => setOpenStartTime(true)},}}
                                />
                            </LocalizationProvider>   
                        </BxItemCont2>
                        <BxItemCont2>
                            <h2>Select End Time</h2> 
                            <LocalizationProvider dateAdapter={AdapterDayjs}> 
                            <DesktopTimePicker
                                value={endTime}
                                onChange={(newValue) => setEndTime(newValue)}
                                sx={timeInputStyle}
                                defaultValue={dayjs('2022-04-17T15:30')} 
                                open={openEndTime}
                                onClose={() => setOpenEndTime(false)}
                                slotProps={{textField: {onClick: () => setOpenEndTime(true)},}}
                                />
                            </LocalizationProvider>   
                        </BxItemCont2>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            marginTop: '20px'
                        }}> 
                        <DatesCont>
                            <h2>Selected Dates</h2>

                            <BxItemW style={{width: 'fit-content'}}>
                            { 
                                selectedDates.map((date, index) => (
                                    <DateBxItem key={date}>
                                        <h2>{date}</h2>
                                        <div onClick={() => removeDateFromList(date)}>
                                            <Tooltip title='Remove Date'>
                                                <VscChromeClose style={{fontSize: '15px', cursor: 'pointer'}} />
                                            </Tooltip>
                                        </div> 
                                    </DateBxItem>
                                ))
                            }
                            </BxItemW> 
                        </DatesCont>
                    </div>
                </SlctCont>

                <ButttonWrap style={{ marginTop: '40px', marginBottom: '0px' }} >
                    <SubmitButton 
                        // disabled={createCalendarItemStatus === "LOADING" ? true : false} 
                        onClick={handleSubmit}> 
                            {
                                createCalendarItemStatus === "LOADING" ? <SpinnerSmall />  : "Create"
                            } 
                    </SubmitButton>  
                </ButttonWrap> 

        </CreateCalendarItemForm>
    )
}

const DatesCont = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;  
    h3{
        font-size: 12px;
    } 
`


const BxItemW = styled.div`
    width: 100%;
    display: flex;
    height: 130px;
    background-color: aliceblue; 
    padding: 10px;
    flex-direction: row;
    flex-wrap: wrap; 
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    overflow-y: scroll;
`


const BxItemCont = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px; 
`

const BxItemCont2 = styled.div`
    width: fit-content  ;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px; 
`

const DateBxItem = styled.div`
    width: 150px; 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: fit-content;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 5px;

    svg{
        margin-left: 10px;
        cursor: pointer;
        color: red;
    }
    
`

const SlctCont = styled.div`
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 30px; 
    &::-webkit-scrollbar {
        display: none;
    }
    h2{ 
        font-size: 12px;
    }
`

const TopBar = styled.div`
    width: 100%;
    height: fit-content;
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    h2{
        font-size: 20px;
        color: var(--lightThemeFontColor);
        font-weight: 500;
        margin-bottom: 20px;
    }

`

const EventNameInput = styled.input`
    width: 100%;
    height: 40px;
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    font-size: 15px;
    color: var(--lightThemeFontColor);
    background-color: white;
    transition: 0.3s;
    &:focus{
        border: 1px solid var(--themeColor);
    }
`


const CreateCalendarItemForm = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 600px;
    height: 600px;
    overflow-y: hidden;
    background-color: white;
    border-radius: 10px; 
    padding: 20px;
    color: var(--lightThemeFontColor);

    @media (max-width: 600px){
        width: 100%;
    }
 
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
 `