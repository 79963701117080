import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'  
import { imgUrl } from 'features/constants' 
import BulletinItem from 'components/bulletin/BulletinItem';
import BulletinFile from 'components/bulletin/BulletinFile';
import { aboutChurchStore } from 'app/aboutChurchStore';
import PersonCardModal from 'components/aboutChurch/PersonCardModal';
import TimeAgo from 'timeago-react'; 
import { StyledBio, ShowMore } from 'components/aboutChurch/about.styled';
import { CiLocationOn } from "react-icons/ci";

export default function PublicNonMemberViewItems(props) {

    const { viewBulletinFileInModal, currentBulletinFileView } = useSelector((state) => state.bulletin) 
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const date = new Date(props.stats ? props.stats.dateJoined : null) 

    // this is not redux state, it is Zustand 
    const setViewPersonCardModal = aboutChurchStore(state => state.setViewPersonCardModal)
    const setPersonDetails = aboutChurchStore(state => state.setPersonDetails)
    const viewPersonCardModal = aboutChurchStore(state => state.viewPersonCardModal) 
    const [showMore, setShowMore] = useState(false)


    return (
        <ChurchView
            width={props.width}
            >
            <ShortContainer> 
                <Wrap2>
                    <ChurchDetails>
                        { !props.profileImg ?
                            (
                                <span>{props.fullName}</span> 
                            )
                            :
                            (
                                <ImageChurch> 
                                    <img src={`https://api.praysely.com/static/img/profile/${props.profileImg}`} alt="church" /> 
                                    <span>{props.fullName}</span> 
                                </ImageChurch>      
                            )
                        }
                        

                        { props.location && (
                            <Location> 
                                <CiLocationOn/>  
                                {
                                    props.location
                                } 
                            </Location>
                        )}  
                        <AboutChurch>
                            {   props.description &&  
                                    <StyledBio
                                        onClick={() => setShowMore(!showMore)} 
                                        blurBottom={!showMore}
                                        >
                                        {   props.description.split('\n').map((item, index) => {
                                                return (
                                                    <section key={index}>
                                                        {item} 
                                                    </section>
                                                )
                                            })
                                        
                                        }
                                    </StyledBio> 
                            }
                            { showMore ? 
                                    (
                                        <ShowMore >
                                            <p onClick={() => setShowMore(!showMore)} >
                                                Show Less
                                            </p>
                                        </ShowMore>
                                    )
                                    : 
                                    (
                                        <ShowMore>
                                            <p onClick={() => setShowMore(!showMore)}>Show More</p>
                                        </ShowMore>
                                    )
                            }
                            {   props.stats && 
                                    <span
                                        style={{
                                            fontSize: '12px',
                                            marginTop: '10px',
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}
                                        ><p>Members: {props.stats.members}</p> <p>Joined: <TimeAgo datetime={date}/></p></span>
                            }
                        </AboutChurch>
                    </ChurchDetails>    
                </Wrap2> 
            </ShortContainer>

            { props.bulletin && (
                <BulletinWrap> 

                    View latest bulletin:

                    <BulletinItem
                        data={{
                            fileName: props.bulletin.fileName,
                            path: props.bulletin.path
                        }}
                        />

                </BulletinWrap>
            )}
            {
                props.people && (
                    <PeopleWrap>
                        <span>People</span>

                        <PeopleItemsWrap>
                            { props.people.map((person, index) => (
                                    <PersonItem
                                        key={index}
                                        isHovered={hoveredIndex === index}
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        onMouseLeave={() => setHoveredIndex(null)}
                                        onClick={()=> { 
                                            setPersonDetails({
                                                ...person,
                                                image: person.image ? `${imgUrl}/aboutSection/${person.image.imagePath2}` : null
                                            })
                                            setViewPersonCardModal(true)
                                        }} 
                                        > 
                                        { person.image && (
                                            <img src={`${imgUrl}/aboutSection/${person.image.imagePath2}`} alt="person" />
                                        )}
                                    </PersonItem> 
                                ))
                            }
                        </PeopleItemsWrap>
                    </PeopleWrap>
                )
            } 

            {
                viewPersonCardModal && (
                    <PersonCardModal  />
                )
            } 

            {
                viewBulletinFileInModal && currentBulletinFileView && (
                    <BulletinFile file={currentBulletinFileView} />
                )
            } 

        </ChurchView>
    )
}

const ChurchView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: ${props => props.width ? props.width : '50%'};
    height: fit-content;
    margin-top: 20px;
    overflow-y: scroll;
    padding-top: 20px;
    //hide scrollbar

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }


    @media (max-width: 800px) {
        width: 100vw;
        padding: 10px;
        overflow-y: scroll;  
        padding-top: 20px;
    } 
`

const ShortContainer = styled.div`
    margin-top: 10px;
    /* background-color: white; */
    min-width: 500px;
    /* width: fit-content; 
    min-height: 200px;  */
    height: fit-content; 
    /* border: 1px solid red; */
    display: flex; 
    position: relative;
    width: 100%;
    display: flex;
    padding: 10px; 
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    border-radius: 10px;
    @media (max-width: 800px) {
        height: fit-content;
        width: 100%;
        min-width: unset;
    }
`
const Wrap2 = styled.div`   
    width: 100%;
    height: 100%;
    /* border: 1px solid grey; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;  
    top: -60px;
    @media (max-width: 800px) {
        width: 100%;
        padding: 10px;
    }
`

const ImageChurch = styled.div`
    width: 100%;
    height: fit-content;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* border: 1px solid grey; */
    img{
        margin-right: 10px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
    }

`
const ChurchDetails = styled.div`
    width: 100%;
    height: fit-content;  
    background-color: white;
    box-shadow: 0 0 10px #ccc; 
    border-radius: 10px;
    display: flex;
    margin-top: 20px; 
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    span{
        font-size: 20px;
        font-weight: 400;
        color: var(--lightThemeFontColor)
    }

    @media (max-width: 800px) {
        width: 100%;
    
        span{
            font-size: 17px;
        }
    }

`

const Location = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--lightThemeFontColor);
    font-weight: 400;
    margin-top: 15px;

    svg{
        font-size: 25px;
        margin-right: 15px;
    }

    span{
        margin-left: 5px;
        font-size: 12px;
    }
    @media (max-width: 800px) {
        font-size: 12px;
        svg{
            font-size: 20px;
            margin-right: 10px;
        }
    }
`
const AboutChurch = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(82, 82, 82);
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
    text-align: left;
    padding: 20px;
    height: fit-content;
    @media (max-width: 800px) {
        width: 100%;
        padding: 20px;

        span{
            font-size: 14px;
        }
    }
`
const BulletinWrap = styled.div`
    margin-top: 10px;
    width: 100%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--lightThemeFontColor);
`
const PeopleWrap = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 10px;
    color: var(--lightThemeFontColor);
`
const PeopleItemsWrap = styled.div` 
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; 
    padding: 10px;

`

const PersonItem = styled.div`
    height: 120px;
    width: 120px;
    border-radius: 50%;
    /* box-shadow: ${props => props.isHovered ? '0 0 10px #ccc' : 'none'}; */
    margin: 5px 15px 15px 15px;
    transition: all 0.4s ease-in-out;

    img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
    }
    &:hover{
        box-shadow: 0 0 15px #6b6b6b;
    }

`