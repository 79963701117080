import React, { useEffect } from 'react'
import styled from 'styled-components' 
import { useSelector, useDispatch } from 'react-redux';
import { getPrayerWallItems } from 'features/sidebar/sidebarSlice';
import Loader from 'loaders/Loader';
import PrayerWallItem from './PrayerWallItem'; 


export default function PrayerWallFeed(props) {

    const dispatch = useDispatch()
    const { 
        viewSidebar, 
        prayerWallItems,
        getPrayerWallItemsStatus
    } = useSelector((state) => state.sidebar) 

    const { profileChurch } = useSelector((state) => state.profileChurch)

 
    useEffect(() => {
        if(viewSidebar === 'prayerWall'){
            dispatch(getPrayerWallItems({
                churchId: profileChurch._id
            })) 
        } 
    }, [viewSidebar])


    return (  
        <PrayerWallFeedContainer>

            { getPrayerWallItemsStatus === "LOADING" ?  
                (
                    <Loader />
                )
                : getPrayerWallItemsStatus === "SUCCESS" && prayerWallItems.length > 0 && 
                (
                    prayerWallItems.map((item, index) => {

                        return (
                            <PrayerWallItem key={index} item={item} />
                        )
                    
                    })
                )
            } 
        </PrayerWallFeedContainer> 
    )
}
  
const PrayerWallFeedContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: var(--lightThemeBackground);
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`
 