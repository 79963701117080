import React from 'react'
import styled from 'styled-components'  


export default function GlobalBtnWrapper( { children, height, width, boxShadow, theme }) {
    return (
        <GlobalBtnWrapperContainer
            buttonHeight={height}
            buttonWidth={width}
            boxShadow={boxShadow}
            > 
            {children}
        </GlobalBtnWrapperContainer>
    )
}


const GlobalBtnWrapperContainer = styled.div`
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: ${props => props.boxShadow ? '0 0 10px #ccc' : 'none'};
    height: ${props => props.buttonHeight};
    width: ${props => props.buttonWidth};
    border-radius: 10px; 
    color: var(--lightThemeFontColor);
    font-size: 12px; 
    cursor: pointer;
    transition: all 0.2s ease-in-out;

`