import React, {useEffect, useState, useRef} from 'react'
import { resetAddEmailState, sendEmailInvite } from 'features/email/emailSlice'
import { useDispatch, useSelector } from 'react-redux'  
import styled from 'styled-components'
import { SettingsMainContainer, TopHeaderSettings, SettingsInputContainer, SettingsSubmitButton  } from '../settings.styled'; 
import SpinnerSmall from 'components/SpinnerSmall';
import { toast } from 'react-toastify';
import ClearIcon from '@mui/icons-material/Clear';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import { BsPatchQuestion } from "react-icons/bs";
import { Tooltip } from '@mui/material';
import { StyledHelpIcon } from '../settings.styled';

export default function AddMembers() {

    const dispatch = useDispatch() 
    let bulkEmails = useRef(null) 
    const {  sendEmailInviteStatus } = useSelector((state) => state.email) 
    const [ email, setEmail ] = useState('')
    const [ addType, setAddType ] = useState('individual')  
    const [ viewInfo, setViewInfo ] = useState(localStorage.getItem('inviteInfo') !== undefined ? JSON.parse(localStorage.getItem('inviteInfo')) : true)

    const handleSendIndividualEmail = () => {

        let emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(email)) {
            return toast.error('Please enter a valid email address')
        }

        dispatch(sendEmailInvite({
            emails: email 
        }))
    }

    useEffect(() => {
        if (sendEmailInviteStatus === "SUCCESS") {
            toast.success('Email sent successfully')
            setEmail('')  
            dispatch(resetAddEmailState())
        }
    }, [sendEmailInviteStatus, dispatch])

    const toggleViewInfo = (value) => {
        localStorage.setItem('inviteInfo', value)
        setViewInfo(value)
    }

    const handleBulkInvites = () => { 

        if(bulkEmails.current.value === ''){
            return toast.error('Please enter email addresses')
        }

        dispatch(sendEmailInvite({
            emails: bulkEmails.current.value
        }))
    } 

    return (
        <SettingsMainContainer> 
            <TopHeaderSettings> Add Members by Invite </TopHeaderSettings>

            <MainInviteContainer> 

                {
                    !viewInfo && (
                        <Tooltip title="How this works" placement='top'>
                            <StyledHelpIcon
                                onClick={() => toggleViewInfo(true)}
                                >
                                <BsPatchQuestion style={{cursor: 'pointer', fontSize: '30px'}} />                         
                            </StyledHelpIcon>
                        </Tooltip>
                    )
                }

                {
                    viewInfo && (
                        <HeadingH2>
                            <span >
                                Invite members to your church without having them go through the normal process of:
                                <StyledUL iconColor={'red'} >
                                    <li> <ClearIcon /> Filling register form </li>
                                    <li> <ClearIcon /> Verifying their email </li>
                                    <li> <ClearIcon /> Logging in to their account </li>
                                    <li> <ClearIcon /> Searching for your church </li>
                                    <li> <ClearIcon /> Requesting to join your church </li>
                                    <li> <ClearIcon /> You accepting their request </li>
                                </StyledUL> 

                                <div style={{marginTop: '20px', width: '100%', textAlign: 'left'}}>
                                    This process will send each user an invite by email, and upon registering,
                                    users will be automatically added to your church.                  
                                </div>

                                <StyledUL iconColor={'green'}>
                                    <li> <LooksOneIcon/>  Send email invite </li>
                                    <li> <LooksTwoIcon/>  User goes to email link and registers </li>
                                    <li> <Looks3Icon/>  User is added to your church </li>
                                </StyledUL>

                                <div style={{marginTop: '20px', width: '100%', textAlign: 'left'}}>
                                    Note: Upon registering users will become members of your church on Praysely. 
                                    Verify you have correct email addresses for each user.
                                </div>
                            </span>

                            <u
                                style={{cursor: 'pointer', color: 'grey', marginTop: '20px', width: '100%', textAlign: 'left'}}
                                onClick={() => toggleViewInfo(false)}
                                >
                                Hide info
                            </u>

                        </HeadingH2>                        
                    )
                }

                {
                    addType === 'bulk' ?
                    (
                        <div style={{width: '100%'}}>
                            <InviteTextArea 
                                style={{marginTop: '20px'}}
                                ref={bulkEmails}
                                placeholder="Enter email addresses separated by commas"  
                                />       
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between', 
                                }}
                                >
                                <InviteButton
                                    disabled={sendEmailInviteStatus === 'LOADING'}
                                    onClick={handleBulkInvites}
                                    >
                                    { sendEmailInviteStatus === "LOADING" ? <SpinnerSmall /> : 'Send Invites'}
                                </InviteButton> 
                                <ToggleInviteTypeText  onClick={() => setAddType('individual')}> 
                                    <u>Send single invite email</u> 
                                </ToggleInviteTypeText>
                            </div>
                        </div> 
                    ) 
                    :
                    (
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center', 
                            
                            }}
                            >
                            <SettingsInputContainer
                                style={{marginTop: '20px'}}
                                > 
                                <input  
                                    value={email} 
                                    onChange={(e) => setEmail(e.target.value)}
                                    name='email' 
                                    placeholder={'Enter email address'}  
                                    />
                                <SettingsSubmitButton 
                                    disabled={sendEmailInviteStatus === 'LOADING'}
                                    onClick={handleSendIndividualEmail}
                                    > 
                                    {
                                        sendEmailInviteStatus === "LOADING" ? <SpinnerSmall /> : 'Send'
                                    }
                                </SettingsSubmitButton>
                            </SettingsInputContainer>    
                            <ToggleInviteTypeText  
                                style={{width: '100%', textAlign: 'right', marginTop: '20px'}}
                                onClick={() => setAddType('bulk')}> 
                                <u>Send bulk email invites</u> 
                            </ToggleInviteTypeText>                        
                        </div>

                    )
                }
   
            </MainInviteContainer> 
        </SettingsMainContainer> 
    )
}


 
const StyledUL = styled.ul`
    list-style-type: none;
    padding: 0;
    line-height: .5;
    margin-top: 15px;
    svg{
        font-size: 15px;
        color: ${props => props.iconColor};
        margin-right: 10px;
    }


    li {
        margin-top: -2px;
        font-size: 11px;
        margin-bottom: -2px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

`

const MainInviteContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px; 
`
const HeadingH2 = styled.h2`
    color: var(--LightThemeFontColor);
    font-size: 13px;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
    text-align: left;
`

const InviteTextArea = styled.textarea`
    width: 100%;
    outline: none;
    border: none;
    min-height: 250px; 

    // set max width limits for textarea 
    resize: vertical; 


    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 0px 10px #ccc;
    // modify placeholder text
    color: var(--LightThemeFontColor);
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    &::placeholder {
        color: var(--LightThemeFontColor);
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
    }
`
const InviteButton = styled.div`
    display: flex;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    width: fit-content;
    background-color: var(--themeColor);
    border: 3px solid var(--themeColor);
    color: white;
    font-weight: 400;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    outline: none;
    transition: all 0.5s;
    
    /* icon is hidden and unhide it on hover */
    svg { 
        margin-right: 9px; 
        font-size: 19px;
    }

    &:hover {
        transition: all 0.5s;
        background-color: white;
        color: var(--themeColor); 
    }
`
const ToggleInviteTypeText = styled.div`
    color: var(--LightThemeFontColor);
    font-size: 13px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    font-weight: 400; 
    font-family: 'Roboto', sans-serif;
    text-align: left;
    cursor: pointer;
    margin-top: 20px;
`