import React, { useEffect } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import styled from 'styled-components' 
import Modal from '@mui/material/Modal';  
import { useSelector, useDispatch } from 'react-redux'
import Tooltip from '@mui/material/Tooltip';
import { getEvents, setViewEventModal, setViewCalendarItemInfoModal, setViewAddCalendarItemModal } from 'features/events/eventSlice'
import LoaderSmall from 'loaders/LoaderSmall'
import CreateButton from 'components/buttons/CreateButton'
import { FaQuestionCircle } from "react-icons/fa";
import CreateCalendarItem from './CreateCalendarItem'
import { setViewSidebar, setEventItem } from 'features/sidebar/sidebarSlice' 

const localizer = momentLocalizer(moment)

export default function CalendarComponent() {

    const dispatch = useDispatch()

    const { user, admin } = useSelector(state => state.auth)
    const { eventsList, calendarItems, isEventLoading, viewCalendarItemInfoModal, viewAddCalendarItemModal } = useSelector(state => state.events)
    const { profileChurch } = useSelector(state => state.profileChurch)
    const [list, setDates] = React.useState([])
    
    const handleSelectEvent = (event) => {
        console.log(event)
        dispatch(setViewSidebar("events"))
        dispatch(setEventItem({
            name: event.title,
            start: event.start,
            end: event.end
        }))
    }

    const handleCreateCalendarItem = () => {
        dispatch(setViewAddCalendarItemModal(true))

    }

    const handleInfoClick = () => {

        dispatch(setViewCalendarItemInfoModal(true))

    }

    const handleSelectSlot = (event) => {

        if(!admin[profileChurch._id] && !user.isOrganization){
            return
        }else{
            dispatch(setViewEventModal({  
                // startDate: event.start,  
            }))         
        }
    }

    useEffect(() => {
        if(eventsList.length === 0){ 

            dispatch(getEvents({
                ids: profileChurch._id
            }))
        }
    }, [])

    let setDatesFunction = () => {
        let dates = []

        eventsList.map((event, index) => {
            const start = event.startDate.split('-')
            const end = event.endDate ? event.endDate.split('-') : null 

            dates.push({
                id: index,
                title: event.eventName,
                allDay: false,
                start: new Date (event.startDate ),
                end: end ? new Date (event.endDate ) : new Date (event.startDate + 'Z'),
            
            })      
        })
        setDates(dates)
    }
    
    useEffect(() => {
        if(eventsList.length > 0){
            setDatesFunction()
        }
 
        if(calendarItems.length > 0){ 
            calendarItems.forEach((item, index) => { 
                item.ongoingDates.forEach((date, index) => {
                    let starDate = date.date.slice(0, date.date.indexOf('T')) + 'T' + date.startTime                    
                    let  endDate = date.date.slice(0, date.date.indexOf('T')) + 'T' + date.endTime

                    setDates(prev => [...prev, {
                        id: starDate,
                        title: item.eventName,
                        allDay: false, 
                        start: moment(starDate).toDate(),
                        end: moment(endDate).toDate(),
                    }])
                })
            })
        }
    }, [eventsList, calendarItems ])

    return (
        <CalendarContainer> 

            <Calendar
                localizer={localizer}
                events={list}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                selectable
                />
{/* 
            <Tooltip title="Sync Calendar"> 
                <SyncCalendar> 
                    < SyncIcon /> 
                </SyncCalendar>
            </Tooltip> */}

            <Modal
                open={isEventLoading}
                onClose={() => {}}
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                
                <LoaderSmall />
                
            </Modal> 
        </CalendarContainer>
    )
}

const CalendarContainer = styled.div`  
    margin-top: 30px;


`

const MCVGFN = styled.div`
    width: 500px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    justify-content: center;
    padding: 20px;
    color: var(--lightThemeFontColor);
    border-radius: 10px;

`
