import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SendIcon from '@mui/icons-material/Send'; 
import { deletePost,setSelectPostToViewPrayers, likePost, setQuickMessageData, savePost, setViewWhoIsPrayingModal } from '../features/posts/postSlice'
import TimeAgo from 'timeago-react'; 
import {  MdEmojiPeople } from 'react-icons/md'
import '../css/components/postItem.css'
import { setViewSendQuickMessageModal } from 'features/posts/postSlice'
import useToggleDropdown from '../hooks/useToggleDropdown';
import { FaPray } from 'react-icons/fa'
import { imgUrl } from 'features/constants';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { setConfirmModalData, setViewConfirmModal } from 'features/modal/modalSlice'; 
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import styled from 'styled-components'; 
import PostItemImage from './posts/PostItemImage';
import { addItemToPrayerWall } from 'features/sidebar/sidebarSlice';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { DotEle, DotsWrap } from './dots/dots.styled';
import { language } from 'app/language';
import { PostTextWrap, PostSubText, SeeMoreBtn, SeeMore  } from './main.styled'; 

import * as timeago from 'timeago.js'; 
import es from 'timeago.js/lib/lang/es';
import SpinnerSmall from './SpinnerSmall';
timeago.register('es', es);

function PostItem( {index, post, postId, canViewWhoisPraying } ) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ref1 = useRef(null)
  const [postText, setPostText] = useState(post.postText.text)
  const [isDropped, setIsDropped] = useToggleDropdown(ref1)
  const handleDropDown = () => { setIsDropped.toggle(ref1) }
  const [postLikes, setPostLikes] = useState() 
  const [color, setColor] = useState('gray')
  const { user, admin, moderator } = useSelector((state) => state.auth)
  const { likePostLoading, isPostLikeSuccess, isPostLikeLoading } = useSelector((state) => state.posts)
  const [profileImage, setProfileImage] = useState('generic.png') 
  const { profileChurch } = useSelector((state) => state.profileChurch)
  const [isUserInLikes, setIsUserInLikes] = useState(false) 

  useEffect(() => { 
    if(post.likes && post.likes.length > 0){
      setPostLikes(post.likes.length)
    }else{
      setPostLikes(0)
    }
    if(post.creator.profileImg && !post.isAnonymous ){ 
      setProfileImage(post.creator.profileImg.imagePath2)
    } 
    if(post.likes.find( ele => ele.toString() === user._id)){
      setIsUserInLikes(true)
      setColor('#00B4CC')
    } 

}, [ user, navigate, post, profileChurch.moderators, profileChurch])

  useEffect(() => {
    if(isUserInLikes){ setColor('#00B4CC') }
  }, [isUserInLikes])

  useEffect(() => {
    if(post.postText.isLongText){  setPostText(post.postText.text.slice(0, 650)) }
  }, [])

  const handleLikePost = () => { 
    if(user._id === post.creator._id && post.likes.length > 0){
      dispatch(setSelectPostToViewPrayers({
        id: post._id,
        usersPraying: post.likes
      }))

      //show modal with users who are praying
      dispatch(setViewWhoIsPrayingModal(true))
      return 
    }else if(isUserInLikes){
      return
    }else{ 
      dispatch(likePost({
        postId: post._id,
        index: index
      }))      
    } 
  }
 
  let newDate = new Date(post.createdAt)

  const handleGoToPost = () => {   navigate(`/posts/${postId}`)   }  
  const handleSavePost = () => {
    dispatch(savePost({ 
      postId: post._id,
      index: index
    }))
  }
  const handleDelete = () => { 
    dispatch(setConfirmModalData({ 
      text: 'Are you sure you want to delete this post?',
      handler: () => dispatch(deletePost({  postId: post._id, index: index })),
      confirmBtnBackgroundHover: '#642419',
    })) 
    dispatch(setViewConfirmModal(true))
  }

  const handleSendQuickMessage = () => {
    dispatch(setQuickMessageData({
      userId: post.creator._id,
      fullName: post.creator.fullName,
      userType: post.userType,
      profileImage:  post.creator.profileImg.imagePath2,
    }))
    dispatch(setViewSendQuickMessageModal(true))
  }

  let profileImageToShow;
  try{  profileImageToShow =  `${imgUrl}/profile/${profileImage}` }
  catch(e){ profileImageToShow =  `${imgUrl}/profile/${profileImage}`} 

  const handleAddToPrayerWall = () => {
    dispatch(addItemToPrayerWall({
      churchId: profileChurch._id,
      postData: {
        postDate: post.createdAt,
        userId: post.creator._id,
        fullName: post.creator.fullName,
        postText: post.postText.text,
        postTextCondensed: post.postText.postTextCondensed ? post.postText.postTextCondensed : post.postText.text,
        userImage: post.creator.profileImg.imagePath2
      },
      postId: post._id
    }))
  }

  return (
    <div 
      style={{zIndex: `${Math.abs(99 - Number(index))}`}} 
      className='post' 
      >  
      <div className='postInfo'>
        <div className='postItemDate' > 
        <TimeAgo 
          datetime={newDate}
          locale={user.preferredLanguage}
          />
        </div> 
        <div className="postItemDots" ref={ref1}>
          <div onClick={handleDropDown}><MoreHorizIcon /></div>
            { isDropped && (
              <DotsWrap> 
                  <DotEle onClick={handleGoToPost}> 
                    <NewspaperIcon className='postItemDotsIcon'/> 
                    {language.viewPost[user.preferredLanguage]}
                  </DotEle>

                  {
                    !post.isAnonymous && post.creator._id !== user._id &&
                      <DotEle onClick={handleSendQuickMessage}> 
                        <SendIcon className='postItemDotsIcon'/> 
                        Send Message
                      </DotEle>
                  }
                  <DotEle onClick={handleSavePost}> 
                    <SaveAltIcon className='postItemDotsIcon'/>
                    {language.save[user.preferredLanguage]} 
                  </DotEle>
                  { (post.creator._id === user._id || post.postedToId.toString() === user._id || admin[profileChurch._id] ||  moderator[profileChurch._id]) && (
                      <>
                        <DotEle onClick={handleAddToPrayerWall}> 
                        <FormatListBulletedIcon className='postItemDotsIcon'/>
                         {language.addToPrayerWall[user.preferredLanguage]}
                        </DotEle>
                        <DotEle style={{color: 'red'}} onClick={handleDelete}> <DeleteOutlineIcon className='postItemDotsIcon' /> 
                          {language.delete[user.preferredLanguage]}
                        </DotEle>
                      </>
                    )
                  }
              </DotsWrap>
            )}
          </div>
        </div>
        <div className="postItemTop">
          <div className='postUser'>
            <div className='postItemUserImg' > 
              {
                !post.isAnonymous ? 
                (
                  <img 
                    className="postImageImg" 
                    src={profileImageToShow} 
                    alt="u" /> 
                )
                :
                (
                  <AccountCircleIcon  style={{ color: 'var(--lightThemeFontColor)',  width: '35px',  height: '35px', }} />
                )
              }
            </div>
            <div className='postItemName' >
              { post.isAnonymous ? (<i>Anonymous</i>) : (post.creator.fullName) }  
              <StyledMTA> <TimeAgo datetime={newDate}/> </StyledMTA>
            </div>

            <PostItemType
              style={{
                backgroundColor: post.postType === 'Prayer Request' ? '#46801fac' : post.postType === 'Praise' ? '#f5a623ac' : '#4a90e2ac' 
              }}
              visible={true} 
              > 
                {language[post.postType][user.preferredLanguage]} 
            </PostItemType> 
          </div>
        </div>

          { (post.hasImage) ? (
              <div className="postItemMiddle" >
                <div className='postImageWrap' onClick={handleGoToPost}> 
                  <PostItemImage image={post.image} />
                </div>
                <PostTextWrap hasImage={true}>
                  {
                    postText.split('\n').map((subtext) => {
                    return (
                      <PostSubText>  
                        {subtext}   
                      </PostSubText>
                    )
                  })} 
                  {
                    post.postText.isLongText && (
                      <SeeMore><SeeMoreBtn onClick={handleGoToPost}> See More </SeeMoreBtn></SeeMore>
                    )
                  }  
                </PostTextWrap>
              </div>
            )
            :
            (
              <div className="postItemMiddle" > 
                <PostTextWrap >
                  {postText.split('\n').map((subtext) => {
                    return (
                      <PostSubText> {subtext}   </PostSubText>
                    )
                  })}  
                  {
                    post.postText.isLongText && (
                      <SeeMore>
                        <SeeMoreBtn onClick={handleGoToPost}> See More </SeeMoreBtn>
                      </SeeMore>
                    )
                  }  
                  </PostTextWrap>
              </div>
            )
          }
        <div className='postItemBottom'>
          <div className='postItemActions'>
            <div  className='commentSectionIcon'  onClick={handleGoToPost}>  
                  <QuestionAnswerIcon />
                  <div className='commentSectionIconText' >
                    {post.comments.length > 0 ? 
                      (
                        language.comments[user.preferredLanguage]
                      )
                      :
                      (
                        language.comment[user.preferredLanguage]
                      )
                    }
                  </div>
                  <div style={{ fontSize: '12px', paddingLeft: '5px'}} > { post.comments.length !== undefined && post.comments.length > 0 && (` (${post.comments.length})`)} </div>
            </div>
            
            { post.postType === 'Prayer Request' ? 
            ( 
              <PrayerButtonContainer
                isUserPraying={isUserInLikes}
                onClick={handleLikePost}
                className='commentSectionIcon'
                >
                <FaPray color={color} /> 
                {
                  isPostLikeLoading && likePostLoading === post._id ?
                  (
                    <SpinnerSmall border={'var(--themeColor)'}/>
                  )
                  : user._id === post.creator._id && post.likes.length > 0 ? (
                    <div>
                      {language.seeWhoIsPraying[user.preferredLanguage]}
                    </div>
                  )
                  : 
                  (
                    <div 
                      className='commentSectionIconText'
                      style={{ color: color }}
                      >
                      {
                        isUserInLikes ? 
                        (
                          <>{language.youArePraying[user.preferredLanguage]}</>
                        )
                        :
                        (
                          <>{language.pray[user.preferredLanguage]}</>
                        )
                      } 
                    </div>
                  )
                } 
              </PrayerButtonContainer>
            )
            :
            ( 
              <div 
                className='commentSectionIcon' 
                onClick={handleLikePost}
                style={{ color: color }}
                >    
                <MdEmojiPeople
                  color={color}
                  style={{ fontSize: '14px'}}  
                  onClick={handleLikePost}
                  /> 
                  {
                    isUserInLikes ? (
                      <span>{language.praisingTheLord[user.preferredLanguage]}</span>
                    )
                    :
                    (
                      <span>{language.praiseTheLord[user.preferredLanguage]}</span>
                    )
                  }
                  
              </div> 
            )
          } 
        </div>
      </div> 
    </div>
  )
}

const PrayerButtonContainer = styled.div`

  color: ${props => props.isUserPraying ? '#009eb3' : 'gray'};

  svg{ 
  
  }

`

const StyledMTA = styled.div`
  font-size: x-small;
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }

`

const PostItemType = styled.div`
    padding: 4px 8px;
    color: ${props => props.visible ? 'white' : 'transparent'};
    background-color: ${props => props.visible ? '#46801fac' : 'transparent'};
    border-radius: 4px;
    margin-left: 15px;
    font-size: x-small;
    transition: all 0.5s ease-in-out;
    @media (max-width: 768px) {
      margin-left: 10px;
      padding: 4px 4px;
    }
`

export default PostItem